import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  img: {
    border: "1px solid gray", 
    borderRadius: 10, 
    padding: 5 ,
    width: 155, 
    height: 245,
    '@media print': {
        width: 130,
        height: 205,
        marginTop: 10
    }
  },
  imgMobile: {
    border: "1px solid gray", 
    borderRadius: 10, 
    padding: 5 ,
    width: 140, 
    height: 245,
    '@media print': {
        width: 130,
        height: 205,
        marginTop: 10
    }

  },
  printGridWrapper: {
    '@media print': { 
      margin: "0px !important",
      padding: "0px !important",
      border: "0px solid #FFF",
      borderRadius: "0px !important",
      boxShadow: 'none !important',
      // height: '100%',
      pageBreakInside: 'avoid',
      boxSizing: 'border-box',
      // height: '310px',
      // width: "165px"
      // width: 155, 
      // height: 300,
    }
  },
  printCardWrapper: {
    margin: 'auto', 
    textAlign: "center",
    cursor: "pointer",
    '&:hover': {
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)'
    },
    '@media print': {
      padding: 5,
      // paddingTop: "20px",
      // width: 190,
      // height: 350,
      // width: "160px",
      border: "1px dashed #CCC",
      borderRadius:"0px !important",
      '&:hover': {
        boxShadow: 'none !important',
      },
    },
  },
  
  printTextWrapper: {
    margin: "auto",
    textAlign: "center",
    size: 14,
    maxWidth: 135,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    '@media print': {
      size: 10,
      maxWidth: 120,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"

    }
  }
}));

export default useStyles;