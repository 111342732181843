// import { makeStyles } from "@mui/material/styles"; createStyles
import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  head: {
    // height: 400,
    textAlign: "center",
    // background: "gray",
    padding: "0px 0 0px 0"
  },
  title: {
    fontSize: "3rem",
    // margin: 0,
    fontFamily: "Inter, sans-serif",
    lineHeight: 1.2,
    color: "rgb(45, 55, 72)",
    fontWeight: 700,
    textAlign: "center",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  color: {
    color: "#637381",
  },
  link: {
    color: "#637381",
    textDecoration: "none"
  }

}));

export default useStyles;