import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };


    const faqsArray = [
      {
        id: 'faq1',
        question: '¿Qué es LoteriApp?',
        answer: 'LoteriApp es una aplicación diseñada para crear, personalizar y jugar loterías de forma fácil y divertida.',
      },
      {
        id: 'faq2',
        question: '¿Cómo puedo personalizar mi lotería?',
        answer: 'Puedes agregar hasta 55 figuras, textos y más para personalizar completamente tus tarjetas de lotería.',
      },
      {
        id: 'faq3',
        question: '¿Cuáles son los tipos de loterías que hay?',
        answer: 'Hay tres tipos: la Clásica, la Local y la de la Nube. ',
      },
      {
        id: 'faq3.1',
        question: '¿Que es una lotería Clásica?',
        answer: 'La Clásica sirve para jugar con las figuras clásicas de manera tradicional. No se pueden añadir o quitar figuras. Se puede jugar en linea con tus familiares o amigos.',
      },
      {
        id: 'faq3.2',
        question: '¿Que es una lotería Local?',
        answer: 'El tipo de lotería Local sirve para guardar tus figuras de manera local en tu navegador, este tipo sirve más para imprimir o crear PDF, ya que al estar solo en tu navegador no puedes compartir un enlace para jugar con otras personas.',
      },
      {
        id: 'faq3.3',
        question: '¿Que es una lotería de la Nube?',
        answer: 'El tipo de lotería en la Nube te permite guardar tus figuras en la nube para que puedas acceder a ellas desde cualquier dispositivo, así como compartir un enlace con otras personas para que puedan jugar con la lotería personalizada que creaste.'
      },
      {
        id: 'faq4',
        question: '¿Puedo imprimir mis tarjetas de lotería?',
        answer: 'Sí, puedes imprimir tus tarjetas y tablas directamente desde la aplicación.',
      },
      {
        id: 'faq5',
        question: '¿Puedo exportar mis loterías en formato PDF?',
        answer: 'Claro, puedes crear un archivo PDF de tus loterías al momento de imprimir solo seleccionar "Guardar como PDF" en el listado de tus impresoras.',
      },
      {
        id: 'faq6',
        question: '¿Cómo comparto mis loterías?',
        answer: 'Solo se puede el tipo de lotería Clásica y de la Nube, mediante un enlace o código QR generado automáticamente en la sección de Compartir dentro de la lotería.',
      },
      {
        id: 'faq7',
        question: '¿Es posible jugar desde la aplicación?',
        answer: 'Sí, puedes jugar desde la aplicación, haciendo que la experiencia sea aún más cómoda.',
      },
      {
        id: 'faq8',
        question: '¿Qué dispositivos son compatibles con LoteriApp?',
        answer: 'LoteriApp es compatible con una amplia variedad de dispositivos móviles y de escritorio.',
      }
    ];

  return (<>
  
  <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Preguntas Frecuentes
      </Typography>

      <Box sx={{ width: '100%' }}>

        {faqsArray.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faq.id}-content`}
              id={`${faq.id}-header`}
            >
              <Typography component="h3" variant="subtitle2" style={{fontWeight: "bold"}}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      </Box>
    </Container>
  </>);
};

export default Faq;







