import { Url } from "../constants/url";
import { LotteryTypeEnum } from "../models/lottery-type-enum";
import Lottery from "../models/lottery";
import Figure from "../models/figure";
import {openDB} from 'idb';

const newLotteryLocal = {
  id: "0",
  name: "Local",
  description: "Guarda tus figuras de lotería de forma local en tu navegador.",
  image: "../../assets/images/general/OIG3.jpg",
  url: Url.GameEditLocal,
  type: LotteryTypeEnum.Local,
  figures: [],
  isReadOnly: false
};

export const local = new Lottery(newLotteryLocal);

const lotteryDB = "lotteryDB";
const lotteriesObj = "lotteries";

const openLotteryDB = async () => {
  const db = await openDB(lotteryDB, 1, {
    async upgrade(db) {
      if (!db.objectStoreNames.contains(lotteriesObj)) {
        db.createObjectStore(lotteriesObj, { keyPath: 'id' });
      }
    }
  });

  const tx = db.transaction(lotteriesObj, 'readwrite');
  const store = tx.objectStore(lotteriesObj);
  const lottery = await store.get(newLotteryLocal.id);

  if (!lottery) {
    await store.add(newLotteryLocal); 
    await tx.done; 
  }

  return db;
}

local.GetFigures = async (idLottery:string) => {
  const db = await openLotteryDB();
  const tx = db.transaction(lotteriesObj, 'readonly');
  const store = tx.objectStore(lotteriesObj);
  const lottery = await store.get(idLottery);

  if (lottery) {
    
    local.figures = [];

    lottery.figures.forEach((figureData: Figure, index: string | number) => {
      const figure = new Figure(figureData);
      if (figureData.image)
        figure.url = URL.createObjectURL(figureData.image!);
      local.figures.push(figure);
    });

    return lottery.figures ?? [];
  } else {
    console.error('Lottery not found.');
    return [];
  }
}

local.AddUpdateFigure = async (id:string, fig: Figure) => {
  const db = await openLotteryDB();
  const tx = db.transaction(lotteriesObj, 'readwrite');
  const store = tx.objectStore(lotteriesObj);
  const lottery = await store.get(id);

  if (lottery) {
    const index = lottery.figures.findIndex((x: Figure) => x.id === fig.id);
    
    const isNew = index === -1;

    if (isNew) {
      lottery.figures.push(fig);
    }
    else {
      lottery.figures[index] = fig;
    }

    await store.put(lottery);
    await tx.done; 
    return lottery.figures;
  } else {
    console.error('Lottery not found.');
    return [];
  }
}

local.DeleteFigure = async (id:string, idFigure: string) => {
  const db = await openDB(lotteryDB, 1);
  const tx = db.transaction(lotteriesObj, 'readwrite');
  const store = tx.objectStore(lotteriesObj);
  const lottery = await store.get(id);

  if (lottery) {

    const index = lottery.figures.findIndex((x: any) => x.id === idFigure);
    
    if (index !== -1) {
      
      lottery.figures.splice(index, 1);
      await store.put(lottery);
      await tx.done;
      
      return lottery.figures;

    } else {
      console.error('Figure not found.');
      return lottery.figures;
    }
  } else {
    console.error('Lottery not found.');
    return [];
  }
}

local.DeleteAllFigures = async (id:string) => {
  const db = await openDB(lotteryDB, 1);
  const tx = db.transaction(lotteriesObj, 'readwrite');
  const store = tx.objectStore(lotteriesObj);
  const lottery = await store.get(id);

  if (lottery) {
    
    lottery.figures = [];

    await store.put(lottery);
    await tx.done;
      
    return [];
  
  } else {
    console.error('Lottery not found.');
    return [];
  }
}


export default local;
