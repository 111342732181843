import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { ImageLoaderProps } from './props';
import { Button } from '@mui/material';
import { Brightness1 } from '@mui/icons-material';

    
const ImageLoader = (props: ImageLoaderProps) => {
  const [loading, setLoading] = useState(true);
  const [loadingImg, setLoadingImg] = useState(true);
  const [checked, setChecked] = useState(false);

  const handleImageLoad = () => {
    setLoadingImg(false);
  };

  useEffect(() => {
    if ((!props.src))
        setLoading(true);
    else
        setLoading(false);
  }, [props.src])

  
  useEffect(() => {
    setChecked(false);
  }, [props.cleanGame])

  return (<>
      {loading || loadingImg
       ? 
        <Skeleton 
            variant='rectangular'
            animation="wave"
            width={props.loaderWidth || 70} 
            height={props.loaderHeight || 111} />
       :
        <>
          
          {props.modeGame ? 
            <Button onClick={() => setChecked(val => !val)} style={{padding: 1}} >
              <img
                src={props.src}
                alt={props.alt}
                className={props.className}
                style={{...props.style, filter: checked ? "grayscale(100%)" : "grayscale(0%)" }}
              />
              {checked && <>
                {/* BlurCircular */}
                <Brightness1 
                  style={{
                    position: "absolute", 
                    top: 15, 
                    left: -6, 
                    fontSize: 85,
                  }} />
                </>}
            </Button>
            :
            <img
            src={props.src}
            alt={props.alt}
            className={props.className}
            style={{...props.style}}
          />
          }
        </>
      }

      <img
          // loading="lazy" 
          src={props.src} alt=""
          style={{display: "none"}}
          onLoad={handleImageLoad}
        />
  </>);
};

export default ImageLoader;
