import {  IconButton, Toolbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import AuthComponent from "../Auth/";
import useStyles from "./css";
import { AppShortcut, BugReport } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Url } from "../../constants/url";

export default function Header() {
    
  const theme = useTheme();
  const isMobileMd = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();
  const [env, setEnv] = useState("");

  useEffect(() => {
    setEnv(process.env.REACT_APP_ENV!);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process.env.REACT_APP_ENV!])
  
  return (<span className="no-print">

      <Toolbar className={classes.head} sx={{ borderBottom: 0, borderColor: 'divider' }}>

        <h1 className={classes.title} >
          <Link color="inherit" to={"/"} className={classes.title} style={{ textDecoration: 'none' }}>
            LoteriApp
          </Link>
          {(env === 'beta' || env === 'local') ? <BugReport style={{marginLeft: 5, fontSize: 18, display: "inline", color: "#fa541c"}} /> : ""}
        </h1>
        
        {isMobileMd ? "" : 
        <div className={classes.lottery}>
          <Tooltip title={"Ir a Loterías"} >
            <IconButton>
              <Link
                to={Url.Dashboard}
                color="secondary"
                style={{ color: "#fa541c", }}
              >
                <AppShortcut />
              </Link>
            </IconButton>
          </Tooltip>
        </div>
        }

        <div className={classes.auth}>
          <AuthComponent />
        </div>

      </Toolbar>
    
  </span>);
}