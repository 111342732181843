import { useState } from "react";
import { Box, Button, Container, Grid, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from 'react-router-dom';
import { CloudCircle, DatasetLinked } from "@mui/icons-material";
import ImageSearchTwoToneIcon from '@mui/icons-material/ImageSearchTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import CasinoIcon from '@mui/icons-material/CasinoTwoTone';

import ImgMain from "../../assets/images/general/body.png"; // mexican1.png
import Table from "../Table";
import { Url } from "../../constants/url";
import useStyles from "./css";
import ImageLoader from "../ImageLoader";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";


export default function Home() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const gaEventTracker = useAnalyticsEventTracker('Home');

  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 1,
  };


  return (<>

    <Container component="main" sx={{mt: 2, mb: 2}} >

      <Grid container spacing={1}>
            
        <Grid item xs={12} md={6}>
          <div style={{
            margin: 0,
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
            }}>
            <h1 className={classes.title}>
              <span className={classes.titleText}>
                Crea tus Propias Tablas de Lotería
              </span>
              <span className={classes.titleOk}> 
                Personalizada
              </span>
            </h1>
            <p className={classes.dsc}>
              Utiliza las figuras tradicionales de la lotería 
              o carga tus propias imágenes.
            </p>
          </div>
        </Grid>

        <Grid item xs={12} md={6} style={{textAlign: "center", alignItems: "center", }}>
          <div  className={classes.imageContainer}>
            <ImageLoader 
              src={ImgMain} 
              alt={""} 
              loaderHeight={'400px'}
              loaderWidth={'400px'}
              style={{
                margin: "auto",
                padding: 0,
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              />
            <img src={ImgMain}
                alt="dama"
                style={{
                  // marginLeft: '1rem',
                  // width: '100%', 
                  // height: '400px',
                  // maxWidth: "350px",
                  maxWidth: '100%',
                  maxHeight: '100%',

                  margin: "auto",
                  padding: 0,
                  display: "none"
                  // borderRadius: '50%', 
                  // objectFit: 'cover',
                }}
              />
          </div >
        </Grid>

      </Grid>

    </Container>

    <Container
            sx={{
              mt: 10,
              mb: 15,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          <Grid container spacing={1}>
            
            <Grid item xs={12} md={6} style={{textAlign: "center"}} order={{ xs: 2, sm: 2, md: 1}}>
              <div 
                style={{
                  transform: "rotate(-13deg)", 
                  textAlign: "center",
                  paddingLeft: 0,
                  minWidth: 260,
                  // backgroundColor: "red"
                }} >
                <Table key={index} index={0} tablesSelected={0} figures={null} style={{height: 480, width: 340}} />
              </div>
            </Grid>

            <Grid item xs={12} md={6} style={{textAlign: "center", marginBottom: 70}} order={{ xs: 1, sm: 1, md: 2 }}>
              <p className={classes.dscTable} style={{margin: isMobile ? "0px" : "50px 0 0 0"}} >
                Nuestra plataforma intuitiva te guiará a través de un proceso de creación de tablas de lotería paso a paso. 
                Tan fácil como revolver al azar las imágenes con un botón:
              </p>

              <Button
                color="secondary"
                size="large"
                variant="contained"
                component="a"
                sx={{ mt: 8 }}
                style={{ backgroundColor: '#fa541c' }}
                onClick={() => {
                  gaEventTracker('mix');
                  setIndex(val => val+1);
                }}
              >
                <CasinoIcon style={{ paddingRight: 10}} />
                Revolver
              </Button>

            </Grid>
          </Grid>
        
    </Container>
    
    <Container
            sx={{
              mt: 5,
              mb: 0,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

        <Typography variant="h4"  component="h2" sx={{ mb: 5 }} className={classes.dscSteps}>
          ¿Cómo funciona?
        </Typography>

        <Grid container spacing={15}>

          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <ImageSearchTwoToneIcon className={classes.iconSteps} />
              <Typography variant="h5" align="center" className={classes.dscSteps}>
                Elige entre las figuras tradicionales de la lotería o sube tus propias imágenes para crear diseños únicos.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <PrintTwoToneIcon className={classes.iconSteps} />
              <Typography variant="h5" align="center" className={classes.dscSteps}>
                Genera las tablas de lotería de manera automática. 
                Imprime o guarda las tablas en formato PDF.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <CloudCircle className={classes.iconSteps} />
              <Typography variant="h5" align="center" className={classes.dscSteps}>
                Guarda tu lotería en la nube para que puedas accesar a ella desde donde quieras. 
                Solo necesitas autenticarte primero.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <DatasetLinked className={classes.iconSteps} />
              <Typography variant="h5" align="center" className={classes.dscSteps}>
                Crea un enlace y comparte con tus amigos o familiares para que juntos jueguen la lotería que diseñaste.
              </Typography>
            </Box>
          </Grid>

        </Grid>
        
        <Button
          component={Link} 
          to={Url.Dashboard}
          color="secondary"
          size="large"
          variant="contained"
          sx={{ mt: 20 }}
          style={{ backgroundColor: '#fa541c' }}
        >
          ¡Vamos a ello!
        </Button>

        <p className={classes.dsc} >
          Empieza a crear tus tablas de lotería hoy mismo con LoteriApp. 
          Únete a nuestra comunidad de jugadores y descubre nuevas formas de disfrutar este clásico juego. 
          ¡No esperes más y comienza tu experiencia única ahora mismo!
        </p>

    </Container>


  </>);
}