import Figure from "./figure";
import ILottery from "./ilottery";
import LinkModel from "./link-model";
import { LotteryTypeEnum } from './lottery-type-enum';

export default class Lottery implements ILottery {
    id: string;
    name: string;
    description: string;
    image: string;
    imageFile: File | undefined;
    url: string;
    type: LotteryTypeEnum | undefined;
    figures: Figure[];
    isReadOnly: boolean;
    idLink?: string;
  

    constructor(x?: any) {
        if (typeof x === "object" && x !== null && x !== undefined) {
            this.id = x.id;
            this.name = x.name;
            this.description = x.description;
            this.image = x.image;
            this.imageFile = x.imageFile;
            this.url = x.url;
            this.type = x.type;
            this.figures = x.figures;
            this.isReadOnly = x.isReadOnly;
            this.idLink = x.idLink;
        }
        else {
            this.id = "";
            this.name = "";
            this.description = "";
            this.image = "";
            this.imageFile = undefined;
            this.url = "";
            this.type = undefined;
            this.figures = [];
            this.isReadOnly = true;
            this.idLink = "";
        }
    }
    
    Set = (x: any) => {
        return;
    }

    Get = async (id:string, attempt?: number): Promise<any> => {
        return this;
    }
    
    Get4Game = async (link: LinkModel): Promise<any> => {
        return null;
    }

    AddUpdate = async (lottery?: Lottery, user?: any) => {
        return;
    }

    GetFigures = async (idLottery: string, idUser?: string) => {
        return this.figures;
    }

    AddUpdateFigure = async  (id:string, image: Figure) => {
        this.figures.push(image);
        return this.figures;
    }

    DeleteFigure = async (id:string, idFigure: string): Promise<Figure[]> => {
        const index = this.figures.findIndex(image => image.id === idFigure);
        if (index !== -1) {
            this.figures.splice(index, 1);
        }

        return this.figures;
    }

    DeleteAllFigures = async (id:string) => {
        this.figures = [];
        return this.figures;
    }

    GetLotteries?: ((userId: string) => Promise<any[]>);
}

