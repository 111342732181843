import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  title: {
    fontSize: "2.75rem",
    margin: 0,
    fontFamily: "Inter, sans-serif",
    lineHeight: 1.2,
    color: "#637381",
    fontWeight: 700,
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    textAlign: "left",
    whiteSpace: "break-spaces"

  },
  titleText: {
    whiteSpace: "break-spaces",
    marginRight: 10
  },
  titleOk: {
    color: "#fa541c",
    whiteSpace: "break-spaces"
  },
  dsc: {
    margin: "100px 0px 0px 0px",
    lineHeight: 1.5,
    fontSize: "1.25rem",
    fontWeight: 400,
    color: "#637381",
    fontFamily: "Helvetica, Arial, sans-serif",
    textAlign: "center",
    padding: "0px 20px"
  },
  dscTable: {
    lineHeight: 1.5,
    fontSize: "1.25rem",
    fontWeight: 400,
    color: "#637381",
    fontFamily: "Helvetica, Arial, sans-serif"
  },
  dscSteps: {
    fontSize: "18px", 
    color: "#637381",
    fontWeight: "bold"
  },
  iconSteps: {
    fontSize: "80px !important", 
    marginBottom: 25, 
    color: "#fa541c"
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px', // Ajusta la altura según tus necesidades
  },


}));

export default useStyles;