import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  printWrapper: {
    // backgroundColor: "red",
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: "97vw",
    padding: 0,
    '@media print': {
      display: 'inherit',
      flexDirection: 'inherit',
      minHeight: '95vh',
    },
  }

}));

export default useStyles;