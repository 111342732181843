// import Figure01 from "./figures/01.png"

const ImgFigures = async () => {
    
    const images: string[] = [];
  
    for (let i = 1; i <= 54; i++) {
      const paddedIndex = i.toString().padStart(2, '0');
      const imageModule = await import(`./figures/${paddedIndex}.png`);
      const image = imageModule.default;
      images.push(image);
    }
  
    return images;
  };
  
  export default ImgFigures();
  