import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

import store from "../redux/store";
import { setUser } from "../auth/auth-slice";

import {firebaseConfig as firebaseConfigBeta} from './firebaseConfigBeta';
import {firebaseConfig as firebaseConfigProd} from './firebaseConfigProd';

const firebaseConfig = process.env.REACT_APP_ENV === 'prod' ? firebaseConfigProd : firebaseConfigBeta; // local

export const app = initializeApp(firebaseConfig);

export const db =  getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app, firebaseConfig.storageBucket);
export const analytics = getAnalytics(app);

auth?.onAuthStateChanged((user) => {
    if (user) {
      store.dispatch(setUser(user));
    } else {
      store.dispatch(setUser(null));
    }
  });
  

