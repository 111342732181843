import React, { useRef, useState  } from 'react';

import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip, useTheme, useMediaQuery } from '@mui/material';
// import Tooltip from '@mui/material/Tooltip';
import { Casino, LocalPrintshop, Visibility, VisibilityOff }  from '@mui/icons-material'; //PictureAsPdf
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

import Table from '../Table';
import { LotteryTypeEnum } from '../../models/lottery-type-enum';
import { TablePdfProps } from './props';

// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import { useReactToPrint } from 'react-to-print';
// const html2pdf = require('html2pdf.js');


const Tables = (props: TablePdfProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [selectedTables, setSelectedTables] = useState(1);
  const [checked, setChecked] = useState(props.lottery?.type! === LotteryTypeEnum.Classic ? false : true);
  const [printing, setPrinting] = useState(false)
  const [mix, setMix] = useState("");

  // const [open, setOpen] = React.useState(false);
  const componentRef = useRef(null);

  const handleTableSelect = (e: { target: { value: string; }; }) => {
    setSelectedTables(parseInt(e.target.value));
  };
  

  // const handlePdf = (useReactToPrint)({
    
  //   onPrintError: (error) => {
  //     setOpen(false);
  //   },
  //   content: () => componentRef.current,
  //   removeAfterPrint: true,
  //   print: async (printIframe) => {
  //     const document = printIframe.contentDocument;
  //     if (document) {
  //       const html = document.getElementById("div-tables");
  //       // console.log(html);

  //       // https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
  //       var opt = {
  //         margin:       1, //[10, 10, 10, 10],
  //         filename:     'mis_tablas.pdf',
  //         image:        { type: 'jpeg', quality: 0.99 },
  //         html2canvas:  { scale: 1 },
  //         jsPDF:        { format: 'letter', orientation: 'portrait', unit: 'in' } //  , unit: 'in'px
  //       };

  //       // html2pdf(html);
  //       html2pdf().set(opt).from(html).save();
  //       // html2pdf().from(html).save();


  //       setTimeout(() => setOpen(false), 2000);

  //     }
  //   },

  // });

  
  const handlePrint = () => {
    setPrinting(true);
   
    setTimeout(() => {
      window.print();
      setPrinting(false);
      },
      1000);
  };


  return (<>

    <div className="no-print" style={{width: "100%", textAlign: "right", marginBottom: 50}}>

      <Tooltip title={printing ? "" : "Revolver figuras"} arrow>
        <Button variant='text' onClick={() => setMix(val => uuidv4())}>
            <Casino />
        </Button>
      </Tooltip>

      {props.lottery?.type! === LotteryTypeEnum.Classic ? null : 
      <Tooltip title={checked ? "Ocultar nombre de figuras" : "Mostrar nombre de figuras"} arrow>
        <Button onClick={() => setChecked(prev => !prev)}>
          {checked ? <VisibilityOff /> : <Visibility />}
        </Button>
      </Tooltip>
      }   

      <Tooltip title={printing ? "" : "Imprimir"} arrow>
        <Button disabled={printing} onClick={() => {handlePrint()}}>
          <LocalPrintshop />
        </Button> 
      </Tooltip>

      <FormControl sx={{ width: isMobile ? 100 : 120 }} size="small">
        <InputLabel key={1} >Tablas</InputLabel>
        <Select
          value={selectedTables.toString()}
          label="Cantidad"
          onChange={handleTableSelect}
        >
          {
            Array.from({ length: 20 }, (_, index) => (
              <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      
      {/* {props.lottery?.type! === LotteryTypeEnum.Classic ? null : 
        <FormControlLabel control={
          <Checkbox 
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            />} 
          label={<FormHelperText>Nombre de Figura</FormHelperText>}
        />
      } */}
      
      {/* <Tooltip title="Imprimir" arrow> */}
        {/* <IconButton onClick={() => {handlePrint();}}>
          <LocalPrintshop />
        </IconButton> */}
      {/* </Tooltip> */}
      {/* <Tooltip title="Descargar en PDF" arrow>
        <IconButton onClick={() => { setOpen(true); handlePdf();}}>
          <PictureAsPdf />
        </IconButton>
      </Tooltip> */}
     
    </div>
    

    <div 
      id="div-tables"
      key="div-tables"
      ref={componentRef}
      style={{textAlign: "center", alignItems: "center"}}>
    {
      Array.from({ length: selectedTables }, (_, index) => (<>
          <Table 
            key={index+mix} 
            index={index} 
            tablesSelected={selectedTables} 
            figures={props.figures} 
            showName={checked}
          />
      </>))
    }
    </div>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
     
    </>
  );
};

export default Tables;

