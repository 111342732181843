import { configureStore } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk';
import authReducer from "../auth/auth-slice";

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
          serializableCheck: false,
        }).concat(thunk as any),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;


