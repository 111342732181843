// import { makeStyles } from "@mui/material/styles";
import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  printImg: {
    width: '70px', 
    height: '111px',
    '@media print': {
      width: '95px', 
      height: '150px' ,
      margin: 0
    },
  },
  printWrapper: {
    '@media print': {
      alignItems: "center",
      verticalAlign: "middle",
      justifyContent: "center",
      display: "flex",
      width: '99% !important',
      height: '99% !important',
      // backgroundColor: "red",
      paddingTop: 50,
    },
    display: "inline-flex",
  },
  printTableWrapper: {
    '@media print': {
      backgroundColor: "white",
      // width: '100% !important',
      height: '100% !important',
      margin: 0,
      padding: 0,
      overflowY: "hidden",
      overflowX: "hidden"
    },
   
  },

}));

export default useStyles;