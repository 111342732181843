import React, { useEffect, useState  } from 'react';
import { Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Casino, MenuOpen, NavigateBefore, NavigateNext, Repartition }  from '@mui/icons-material';

import { GameCardsProps } from './props';
import Figure from '../../models/figure';
import ImageLoader from '../ImageLoader';
import Swal from "sweetalert2";


const GameCards = (props: GameCardsProps) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [currentIndex, setCurrentIndex] = useState(0);
  const [history, setHistory] = useState<number[]>([]);
  const [shuffleFigures, setShuffleFigures] = useState<Figure[]>([]);
  
  useEffect(() => {
    if (props.figures)
      randomizeFigures();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.figures!]);

  const randomizeFigures = () => {
    if (props.figures) {
    const shuffledFigures = shuffleArray(props.figures!);
    setShuffleFigures(shuffledFigures);
    }
  };

  const handleNext = () => {
    if (currentIndex < shuffleFigures.length - 1) {
      setCurrentIndex((prev) => {
        setHistory([...history, prev]);
        return prev + 1;
      });
    }
  };

  const handlePrev = () => {
    if (history.length > 0) {
      setCurrentIndex(history[history.length - 1]);
      setHistory(history.slice(0, -1));
    }
  };

  const handleReset = () => {
    setCurrentIndex(0);
    setHistory([]);
  };

  
  const handleMix = () => {

        Swal.fire({
          title: 'Confirmar',
          text: 'Se revolverán las figuras de la lotería.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fa541c',
          cancelButtonColor: '#ccc',
          confirmButtonText: 'Sí, revolver figuras',
          cancelButtonText: 'Cancelar',
          customClass: {
            container: 'swalZIndex', // Clase CSS personalizada
          },
      
      }).then((result: any) => {
          if (result.isConfirmed) {
            setCurrentIndex(0);
            setHistory([]);
            randomizeFigures();
          }
      });
  };
  
  const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };


  return (<div style={{backgroundColor: "white", margin: "auto"}}>


      <div className="no-print" style={{width: "100%", textAlign: "right", marginBottom: 20}}>
        
        {props.onClose ? 
              <Button
                variant='outlined'
                onClick={() => props.onClose()} 
                style={{float: "left"}} 
                >
                  <MenuOpen style={{marginRight: 10}} /> {isMobile ? "" : "Menú" }
              </Button> : null
            }

        <Tooltip title={"Ir a inicio"} arrow>
          <Button variant="text" color="primary" onClick={handleReset} disabled={currentIndex === 0} style={{marginRight: 20}}>
            <Repartition style={{marginRight: 10}} /> 
          </Button>
        </Tooltip>

        <Tooltip title={"Revolver"} arrow>
          <Button variant="text" color="primary" onClick={() => handleMix()}>
            <Casino style={{marginRight: 10}} /> 
          </Button>
        </Tooltip>

      </div>

    <Box display="flex" justifyContent="center" marginBottom={2}>
      <Typography style={{fontStyle: "italic", fontWeight: "normal"}} >
        Corre y se va corriendo con...
      </Typography>
    </Box>
    
    <Box display="flex" justifyContent="center" mt={2}>
      <Typography style={{fontWeight: "bold"}} >
        {shuffleFigures[currentIndex]?.name}
      </Typography>
    </Box>

    <Box display="flex" justifyContent="center" mt={2}>
      <Button
          disabled={currentIndex === shuffleFigures.length - 1}
          onClick={handleNext}
          variant={"text"}
        >
        <ImageLoader 
          src={shuffleFigures[currentIndex]?.image!['type']?.toString().includes('image')! 
              ? URL.createObjectURL(shuffleFigures[currentIndex]?.image!) 
              : shuffleFigures[currentIndex]?.url}
          alt={`Figura ${currentIndex + 1}`} 
          className={undefined}
          loaderHeight={316}
          loaderWidth={200}
          style={{
            width: '200px',
            height: '316px',
            border: "1px solid gray",
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)'
          }}
            />
      </Button>

    </Box>
    
    <Box display="flex" justifyContent="center" mt={1} >
      {currentIndex+1} / {shuffleFigures.length}
    </Box>

    <Box display="flex" justifyContent="center" mt={2}>

      <Button
        color="primary"
        disabled={currentIndex === 0}
        onClick={handlePrev}
        style={{marginRight: 10}} 
      >
        <NavigateBefore /> Atrás
      </Button>

      <Button
        disabled={currentIndex === shuffleFigures.length - 1}
        onClick={handleNext}
        variant={"contained"}
        style={{marginLeft: 10}} 
      >
        Siguiente <NavigateNext /> 
      </Button>
    </Box>


  </div>);
};

export default GameCards;

