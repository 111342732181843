import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User  } from "firebase/auth";

interface AuthState {
  user: User  | null;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) { // firebase.User | null
      state.user = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setUser, setError } = authSlice.actions;

export default authSlice.reducer;
