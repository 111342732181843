import React, { useRef, useState  } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip, useMediaQuery, useTheme } from '@mui/material'; // FormHelperText, 
import { AutoFixHigh, Casino, MenuOpen, Visibility, VisibilityOff }  from '@mui/icons-material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { v4 as uuidv4 } from 'uuid';

import Table from '../Table';
import { GameTablesProps } from './props';
import { LotteryTypeEnum } from '../../models/lottery-type-enum';


const GameTables = (props: GameTablesProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [selectedTables, setSelectedTables] = useState(1);
  const [checked, setChecked] = useState(props.lottery?.type! === LotteryTypeEnum.Classic ? false : true);
  const [cleanGame, setCleanGame] = useState(false);
  const [mix, setMix] = useState("");

  const componentRef = useRef(null);

  const handleTableSelect = (e: { target: { value: string; }; }) => {
    setSelectedTables(parseInt(e.target.value));
  };

  return (<>
    <div className="no-print" style={{width: "100%", textAlign: "right", marginBottom: 50}}>
      
      {props.onClose ? 
        <Button
          variant='outlined'
          style={{float: "left"}}
          onClick={() => props.onClose()}>
            <MenuOpen style={{marginRight: 10}} /> {isMobile ? "" : "Menú" }
        </Button> : null
      }

      <Tooltip title="Limpiar marcas" arrow>
        <Button variant='text' onClick={() => setCleanGame(val => !val)}>
            <AutoFixHigh />
        </Button>
      </Tooltip>
      
      <Tooltip title="Revolver figuras" arrow>
        <Button variant='text' onClick={() => setMix(val => uuidv4())}>
            <Casino />
        </Button>
      </Tooltip>

      {props.lottery?.type! === LotteryTypeEnum.Classic ? null : 
      <Tooltip title={checked ? "Ocultar nombre de figuras" : "Mostrar nombre de figuras"} arrow>
        <Button variant='text' onClick={() => setChecked(prev => !prev)}>
          {checked ? <VisibilityOff /> : <Visibility />}
        </Button>
      </Tooltip>
      }     

      <FormControl key={"game-table-form-control"} sx={{ width: isMobile ? 100 : 120 }} size="small">
        <InputLabel key={1} >Tablas</InputLabel>
        <Select
          value={selectedTables.toString()}
          label="Tablas"
          onChange={handleTableSelect}
        >
          {
            Array.from({ length: 4 }, (_, index) => (
              <MenuItem key={index} value={index+1}>{index+1}</MenuItem>
            ))
          }
        </Select>
        {/* <FormHelperText>{isMobile ? "Tablas" : "Número de tablas"}</FormHelperText> */}
      </FormControl>
     
    </div>

    <div 
      id="div-tables"
      key="div-tables"
      ref={componentRef}
      style={{textAlign: "center", alignItems: "center"}}>
      {
        Array.from({ length: selectedTables }, (_, index) => (<>
            <Table 
              key={index+mix} 
              style={{marginRight: 40, marginBottom: 40}} 
              index={index} 
              tablesSelected={selectedTables} 
              figures={props.figures} 
              showName={checked}
              modeGame={true}
              cleanGame={cleanGame}
            />
        </>))
      }
    </div>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
     
    
  </>);
};

export default GameTables;

