import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";

import {  Button, Card, CardActionArea, CardContent, CardMedia,
          Divider, Grid, Tooltip, Typography, useMediaQuery, useTheme 
      } from "@mui/material";

import Lottery from "../../models/lottery";
import { cloud }  from "../../api/cloud";
import { RootState } from "../../redux/store";
// import { Url } from "../../constants/url";
import useStyles from "./css";
import LotteryModal from "../LotteryModal";
import { Url } from "../../constants/url";
import ImageLoader from "../ImageLoader";


export default function LotteriesCloud() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { user } = useSelector((state: RootState) => state.auth);
  const classes = useStyles();
  const [lotteryArray, setLotteryArray] = useState<Lottery[]>([]);
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedLottery, setSelectedLottery] = useState<Lottery | null>(null);

  useEffect(() => {

    if (user && user.uid) {
      async function fetchData() {
        await GetLotteries(user!.uid);
      }
      fetchData();
    }

  }, [user])


  const GetLotteries = async (userId: string) => {
    let loteries: Lottery[] = [];

    const clouds = await cloud.GetLotteries!(userId);

    for(let i = 0; i < clouds.length; i++) {
      const newLottery = new Lottery(clouds[i]);
      newLottery.id = clouds[i].docId;
      newLottery.url = `${Url.GameEditCloud}/${clouds[i].docId}`;
      loteries.push(newLottery);
    }

    setLotteryArray(loteries);
  }

  const handleOpen = (l: Lottery) => {
    setSelectedLottery(l);
    setOpenModal(true);
  }

  const handleSaveChanges = async (l: Lottery) => {

    await cloud.AddUpdate(l, user);
    setOpenModal(false);
    await GetLotteries(user!.uid);

  }

  const handleCloseModal = () => {
    setSelectedLottery(null);
    setOpenModal(false);
  }

  return (<span className="no-print">

    {(lotteryArray?.length > 0) ? <>
      <Divider />
      
      <Typography key="desc-lottery-ad" variant="h6" style={{marginTop: 30}}>
          Administra tus loterías adquiridas
      </Typography>

      <Grid
        container
        spacing={0}
        justifyContent="center"
        style={{ 
          marginTop: 30,
          marginBottom: 50
        }}
      >

        {
        lotteryArray.map((lottery, index) => (<>
          <Grid key={index} item xs={12} sm={6} md={4} mb={4} lg={3} >
              <Card sx={{ maxWidth: 250, maxHeight: 250, margin: "auto", position: "relative" }}>
                <Tooltip title={"Editar"} style={{margin: isMobile ? "auto" : ""}} >
                  <Button 
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      width: 40,
                      height: 40,
                      backgroundColor: "white",
                      zIndex: 99,
                      borderRadius: 85,
                      minWidth: 30,
                    }}
                    onClick={() => handleOpen(lottery)} >
                    <Edit style={{fontSize: 24}} />
                  </Button>
                </Tooltip>
                <CardActionArea >
                  <Link to={lottery.url} className={classes.link}>
                    {
                      (lottery.image.trim() !== "")
                        // ? <CardMedia
                        //     component="img"
                        //     height="180"
                        //     // width={200}
                        //     sx={{ objectFit: "fill" }}
                        //     image={lottery.image}
                        //   /> 
                        ? <CardMedia >
                            <ImageLoader 
                              src={lottery.image}
                              alt={lottery.name}
                              loaderWidth={"100%"}
                              loaderHeight={180} 
                              style={{objectFit: "fill", height: 180, width: "100%"}}
                            />
                          </CardMedia>
                        : <CardMedia 
                            style={{
                              height: 180, 
                              backgroundColor: "#fa541c",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }} > 
                              <div className="userProfile" style={{color: "white", fontSize: "36px"}}>
                                {"L"}
                                {index+1}
                              </div>
                          </CardMedia> 
                    }

                    <Tooltip title={lottery.description || ""} style={{margin: isMobile ? "auto" : ""}} >
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div" className={classes.link}>
                          {lottery.name.trim() || `Lotería ${index+1}`}
                        </Typography>
                      </CardContent>
                    </Tooltip>

                  </Link>
                </CardActionArea>

              </Card>
          </Grid>
        </>))
        } 

      </Grid> </> : null }

      <LotteryModal
        open={openModal}
        lottery={selectedLottery}
        isReadOnly={false}
        onSaveChanges={handleSaveChanges}
        onClose={handleCloseModal}
    />
  </span>);
}