import { Button, Card, CardContent, Grid, useMediaQuery, useTheme } from "@mui/material";

import useStyles from "./css";
import { PlusOne } from "@mui/icons-material";

export interface FigureAddProps {
    openModal: () => void;
}
  
export default function FigureAdd (props: FigureAddProps) {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    
    return (<>

        <Grid item key={2}
            className="no-print" 
            xs={6} sm={4} md={3} lg={2}
        >
            <Card 
                onClick={props.openModal}
                sx={{ 
                    maxWidth: 300, 
                    minHeight:300, 
                    margin: 'auto', 
                    textAlign: "center",
                    cursor: "pointer",
                    ":hover": {
                        boxShadow: 20,
                        },
                    }}>

                <img 
                    src="../../../assets/images/general/cards.png"
                    alt=""
                    loading="lazy" 
                    className={classes.img}
                    style={{width: isMobile ? 140 : 155 }}
                />

                <CardContent style={{padding: "16px"}} >
                    <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        component="a"
                        style={{ backgroundColor: '#fa541c' }}
                        onClick={props.openModal}
                    >
                        <PlusOne style={{ paddingRight: 10}} />
                        Agregar
                    </Button>
                </CardContent>
            </Card>
        </Grid>

    </>);
}