import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from "@firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Swal from 'sweetalert2';

import { auth, db } from "../firebase";
import { setUser, setError } from "./auth-slice";
import cloud from "../api/cloud";


export const signUp = (email: string, password: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setError(""));
      const userCredential = await createUserWithEmailAndPassword(auth,
        email,
        password
      );

      dispatch(setError(null));

      if (userCredential.user) {
        dispatch(setUser(userCredential.user));
        await cloud.AddUpdate(undefined, userCredential.user);
        await sendEmailVerification(userCredential.user);
      }
      
      showMsg("Usuario creado correctamente");
    } catch (error: any) {
      dispatch(setError(getError(error.message)));
      // Swal.fire("Error", error.message, "error");
      return getError(error.message);
    }
  };
};


export const signIn = (email: string, password: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setError(""));
      const userCredential = await signInWithEmailAndPassword(auth,
        email,
        password
      );
      dispatch(setError(null));
      dispatch(setUser(userCredential.user));
    } catch (error: any) {
      dispatch(setError(getError(error.message)));
      // Swal.fire("Error", error.message, "error");
      return getError(error.message);
    }
  };
};

export const signOut = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setError(""));
      await auth?.signOut();
      dispatch(setUser(null));
    } catch (error: any) {
      dispatch(setError(getError(error.message)));
      // Swal.fire("Error", error.message, "error");
      showMsg(getError(error.message), "error", "Error");
    }
  };
};

export const resetPassword = (email: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(setError(""));
      await sendPasswordResetEmail(auth, email);
      // Swal.fire("Listo", "Correo enviado correctamente", "success");
      showMsg("Correo enviado correctamente");
    } catch (error: any) {
      // Swal.fire("Error", error.message, "error");
      // showMsg(getError(error.message), "error", "Error");
      dispatch(setError(getError(error.message)));
      return getError(error.message);
    }
  };
};

export const deleteAccount = () => {
  return async (dispatch: any) => {
    try {
      dispatch(setError(""));
      const user = auth?.currentUser!;
      if (user) {

        const lotteryDB = "lottery";
        const pathMain = `${lotteryDB}/${user.uid}`;

        try {
          
          const lotteryRefMain = doc(db, pathMain);
          const docSnap = await getDoc(lotteryRefMain);

          if (docSnap.exists()) {
            await updateDoc(lotteryRefMain, {
              // email: user.email,
              isActive: false
            });
          }
        }
        catch (error: any) {
          console.error(error);
        }


        await user.delete();
        dispatch(setUser(null));
        // Swal.fire("Listo", "Cuenta eliminada correctamente", "success");
        showMsg("Cuenta eliminada correctamente.");
      }
    } catch (error: any) {
      dispatch(setError(getError(error.message)));
      // Swal.fire("Error", error.message, "error");
      showMsg(getError(error.message), "error", "Error");
    }
  };
};

export const verifyEmail = () => {
  return async (dispatch: any) => {
    dispatch(setError(""));
    const user = auth?.currentUser!;
    if (!user) return;

    await user.reload();
    dispatch(setUser(user));

    try {
      if (!user.emailVerified) {
        await sendEmailVerification(user);
        showMsg("Correo de verificación enviado.");
      } else {
        showMsg("Correo verificado.");
      }
    } catch (error: any) {
      console.error(error.message);
      // dispatch(setError(getError(error.message)));
      showMsg(getError(error.message), "warning", "Verificar Correo");
      return error.message;
    }
  }
};


const showMsg = (msg: string, iconType: any = "success", title: string = "Listo") => {
  
  Swal.fire({
    title: title,
    text: msg,
    icon: iconType,
    showCancelButton: false,
    confirmButtonColor: '#fa541c',
    cancelButtonColor: '#ccc',
    cancelButtonText: 'Cancelar',
  });

}

const getError = (msgError: string) => {
  let msg = "";
      switch (msgError) {
        case "Firebase: Error (auth/email-already-in-use).":
          msg = "El correo ya se encuentra registrado. Intenta iniciando sesión.";
          break;
        case "Firebase: Error (auth/invalid-email).":
          msg = "Correo no valido.";
          break;
        case "Firebase: Error (auth/invalid-login-credentials).":
          msg = "Correo/Contraseña no validos.";
          break;
        case "Firebase: Error (auth/internal-error).":
          msg = "Error interno.";
          break;
        case "Firebase: Error (auth/requires-recent-login).":
          msg = "Se requiere cerrar e iniciar sesión nuevamente.";
          break;
        case "Firebase: Error (auth/too-many-requests).":
          msg = "Espere unos minutos y vuelva a intentar.";
          break;
        case "Firebase: Error (auth/user-token-expired).":
          msg = "Su sesión expiró.";
          break;
        case "Firebase: Error (auth/network-request-failed).":
          msg = "Error de red.";
          break;
        default:
          msg = msgError.replace("Firebase: ", "");
          break;
      }
  return msg;
}