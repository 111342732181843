import {
  Button,
  Drawer,
  TextField,
  Avatar,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Alert,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AccountCircle, Clear, ForwardToInbox, LockOutlined, Login, Logout, MenuOpen, PersonOff, VerifiedUser } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';

import { RootState } from "../../redux/store";
import { signUp, signIn, signOut, resetPassword, deleteAccount, verifyEmail } from "../../auth/auth-service";
import { useTypedDispatch } from "../../redux";
import { setError } from "../../auth/auth-slice";
import useStyles from "./css";
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';


const AuthComponent = () => {
  const dispatch = useTypedDispatch();
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user, error } = useSelector((state: RootState) => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignInDrawerOpen, setIsSignInDrawerOpen] = useState(false);
  const [isAccountDrawerOpen, setIsAccountDrawerOpen] = useState(false);
  const [isFormOk, setIsFormOk] = useState(false);
  const [isEmailOk, setIsEmailOk] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker('Auth');

  const handleSignUp = () => {
    gaEventTracker('sign_up');
    setIsSigning(true);
    dispatch(signUp(email, password)).then((error) => {
      if (!(error)) {
        setIsSignInDrawerOpen(false);
      }
      else 
        setIsSigning(false);
    });
  };

  const handleSignIn = () => {
    gaEventTracker('sign_in');
    setIsSigning(true);
    dispatch(signIn(email, password)).then((error) => {
      if (!(error)) {
        setIsSignInDrawerOpen(false);
      }
      else 
        setIsSigning(false);
    });
  };

  const handleSignOut = () => {
    gaEventTracker('sign_out');
    dispatch(signOut()).then(() => {
      setIsAccountDrawerOpen(false);
      navigate("/");
    });
  };

  const handleDeleteAccount = () => {
    
    gaEventTracker('delete_account_ask');

    setIsAccountDrawerOpen(false);

    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede revertir. ¿Quieres continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fa541c',
      cancelButtonColor: '#ccc',
      confirmButtonText: 'Sí, eliminar cuenta',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {

        gaEventTracker('delete_account_confirmed');
        dispatch(deleteAccount());

      }
    });

  };

  const handleForgotPassword = () => {
    gaEventTracker('forgot_password');
    dispatch(resetPassword(email)).then((error: any) => {
      if (!(error))
        setIsSignInDrawerOpen(false);
    });
  }

  const handleVerifiedEmail = () => {
    gaEventTracker('verified_email');
    dispatch(verifyEmail()).then((error: any) => {
        setIsAccountDrawerOpen(false);
    });
  }

  const handleSignInDrawerOpen = (open: boolean) => {
    setIsSignInDrawerOpen(open);
    setEmail("");
    setPassword("");
    setIsSigning(false);
    dispatch(setError(null));
  }

  useEffect(() => {
    const disableBtns = (email !== "" && password !== "" && password.length > 6);
    setIsFormOk(disableBtns);
  }, [email, password]);

  
  useEffect(() => {
    const disableBtns = (email !== "");
    setIsEmailOk(disableBtns);
  }, [email]);


  useEffect(() => {
    setIsVerified(user?.emailVerified!);
  }, [user?.emailVerified]);

  const authButton = user ? (
    <Button
      color="inherit"
      variant="text"
      className={classes.authButton}
      style={{textTransform: "none"}}
      onClick={() => setIsAccountDrawerOpen(true)}
    >
      {isMobile ? <MenuIcon style={{color: '#fa541c'}} /> : <>
      {user.email}
      <MenuOpen style={{marginLeft: 10}} />
      </>}
    </Button>
  ) : (
    <Button
      color="inherit"
      variant="text"
      className={classes.authButton}
      style={{textTransform: "none"}}
      onClick={() => handleSignInDrawerOpen(true)}
    >
      {isMobile ? <MenuIcon style={{color: '#fa541c'}} /> : <>
      Autenticarse
      <Login style={{marginLeft: 10}} />
      </>}

    </Button>
  );

  return (
    <>

      {authButton}


      {/* LOG IN  */}
      <Drawer
        anchor="right"
        open={isSignInDrawerOpen}
        onClose={() => setIsSignInDrawerOpen(false)}
        // classes={{ paper: classes.drawerPaper }}
        className={isMobile ? classes.drawerUserMobile : classes.drawerUser}
      >
        <br />
        <Grid container className={classes.root}>
          <CssBaseline />
          {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
          <Grid
            className={classes.size}
            item
            xs={12}
            sm={10}
            md={10}
            component={Paper}
            square
          >
            <div className={classes.paper}>
              <div
                onClick={() => setIsSignInDrawerOpen(false)} 
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: isMobile ? "25px" : "60px",
                  top: "50px"
                  
                }}>
                  <Clear />
              </div>
              <Avatar className={classes.avatar} style={{backgroundColor: "#fa541c", marginBottom: 10}}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Autenticarse
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Correo"
                  name="username"
                  autoFocus
                />
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}

                {isSigning ? 
                  <Grid container spacing={2} style={{marginTop: 30}}>
                    <Grid item xs={12} style={{textAlign: "center"}}>
                      <Typography >
                        Validando...
                      </Typography>
                    </Grid>
                  </Grid>
                  :
                  <>
                  <Grid container spacing={2} style={{marginTop: 0}}>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        disabled={!isFormOk}
                        className={classes.submit}
                        onClick = {handleSignUp}
                      >
                        Darse de Alta
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                    
                      <Button
                        variant="contained"
                        disabled={!isFormOk}
                        color="primary"
                        className={classes.submit}
                        onClick = {handleSignIn}
                      >
                        Iniciar sesión
                      </Button>
                    </Grid>
                  </Grid>
                  
                  <Grid container style={{marginTop: 20, }}>
                    <Grid item style={{margin: "auto", height: 60}}>
                      <Button
                          variant="text"
                          disabled={!isEmailOk}
                          color="primary"
                          className={classes.submit}
                          style={{textTransform: "none"}}
                          onClick = {handleForgotPassword}
                        >
                          He olvidado la contraseña
                        </Button>
                    </Grid>
                  </Grid>
                  </>
                }

              </form>
            </div>
          </Grid>
          
          {error && <Grid
            item
            xs={10}
            sm={10}
            md={10}
            marginTop={3}
          >
            <Alert severity="warning">{error}</Alert>
          </Grid>}
        </Grid>


      </Drawer>

      {/* USER  */}
      <Drawer
        anchor="right"
        open={isAccountDrawerOpen}
        onClose={() => setIsAccountDrawerOpen(false)}
        className={isMobile ? classes.drawerUserMobile : classes.drawerUser}
      >

          <div
            onClick={() => setIsAccountDrawerOpen(false)} 
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "20px",
              top: "20px"
            }}>
              <Clear />
          </div>

          <Grid container className={classes.root}>

            <Grid item xs={12} 
              style={{
                padding: 10,
                marginTop: 50,
              }}>
                <Avatar className={classes.avatar} style={{backgroundColor: "#fa541c", margin: "auto"}}>
                  <AccountCircle />
                </Avatar>
                <Typography style={{ fontWeight: "bold", paddingTop: 10}}>
                  {user?.email!}
                </Typography>
            </Grid>

              <Grid item xs={12} >
                {isVerified! ? <>
                  <Typography style={{ fontWeight: "normal", fontSize: 14 }}>
                    <VerifiedUser style={{marginRight: 10, fontSize: 15 }} /> 
                    Correo Verificado
                  </Typography>
                </> : <>
                <Typography style={{ fontWeight: "normal", fontSize: 14 }}>
                    Correo No Verificado
                </Typography>
                </>}
              </Grid>

            <Grid item xs={12} 
              style={{
                marginTop: 20
              }}>
              <Button onClick={handleSignOut} style={{textTransform: "none"}}>
                <Logout style={{marginRight: 10}} />
                Desconectarse
              </Button>
            </Grid>

            {!isVerified && <>
            <Grid item xs={10} 
              style={{
                position: "fixed",
                bottom: 80
              }}>
              <Button onClick={handleVerifiedEmail} style={{textTransform: "none"}}>
                <ForwardToInbox style={{marginRight: 10}} />
                Verificar correo
              </Button>
            </Grid>
            </>}

            <Grid item xs={10} 
              style={{
                position: "fixed",
                bottom: 30
              }}>
              <Button onClick={handleDeleteAccount} style={{textTransform: "none"}}>
                <PersonOff style={{marginRight: 10}} />
                Eliminar mi cuenta
              </Button>
            </Grid>
          </Grid>
      
      </Drawer> 

    </>
  );
};

export default AuthComponent;
