import {  Box, Card, CardActionArea, CardContent, CardMedia, Container,
          Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Lottery from "../../models/lottery";
import { classic } from "../../api/classic";
import { local } from "../../api/local";
import { cloud0 }  from "../../api/cloud";
import { RootState } from "../../redux/store";
import { Url } from "../../constants/url";
// import Pricing from "../Pricing";
import LotteriesCloud from "../LotteriesCloud";
import useStyles from "./css";
import ImageLoader from "../ImageLoader";


export default function Dashboard() {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const { user } = useSelector((state: RootState) => state.auth);
  const classes = useStyles();
  const [lotteryArray, setLotteryArray] = useState<Lottery[]>([]);

  useEffect(() => {

    let loteries: Lottery[] = [];

    loteries.push(classic);
    loteries.push(local);
    
    if (user) {
      if (cloud0.url === "") 
        cloud0.url = `${Url.GameEditCloud}/0`;
      loteries.push(cloud0);
    }

    setLotteryArray(loteries);

  }, [user])


  return (<span className="no-print">

  <Container key={1} component="main" sx={{mt: 2, mb: 2}} className={classes.color} >
    
    <Box className="no-print" >
        <Typography variant="h5">
          Juegos de Lotería
        </Typography>
    </Box>
    
    <Typography key="desc-lottery-" variant="h6" style={{marginTop: 10}}>
        Administra tus loterías
    </Typography>

    <Grid
      container
      spacing={0}
      justifyContent="center"
      style={{ 
        marginTop: 50,
        marginBottom: 50
      }}
    >

      {
      lotteryArray.map((lottery, index) => (<>
        <Grid key={index} item xs={12} sm={6} md={4} mb={4} lg={3} >
          <Tooltip title={lottery.description} style={{margin: isMobile ? "auto" : ""}} >
            
            <Card id={`card-${index}`} sx={{ maxWidth: 250, maxHeight: 250, margin: "auto" }}>
              <CardActionArea  >
                <Link to={lottery.url} className={classes.link}>
                  {/* <CardMedia
                    component="img"
                    height="180"
                    // width={200}
                    sx={{ objectFit: "fill" }}
                    image={lottery.image}
                  /> */}
                  <CardMedia >
                    <ImageLoader 
                      src={lottery.image}
                      alt={lottery.name}
                      loaderWidth={"100%"}
                      loaderHeight={180} 
                      style={{objectFit: "fill", height: 180, width: "100%"}}
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div" className={classes.link}>
                      {lottery.name}
                    </Typography>
                  </CardContent>
                </Link>
              </CardActionArea>
            </Card>
          </Tooltip>
        </Grid>
      </>))
      }

    </Grid>
      
    <LotteriesCloud />

    {/* <Pricing /> */}
          
  </Container>
  </span>);
}