import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Card, CardContent, Typography, Button, TextField, Slider, useMediaQuery, useTheme, Alert, Snackbar } from '@mui/material';
import Cropper from 'react-easy-crop'; // , { getInitialCropFromCroppedAreaPercentages }

import Figure from '../../models/figure'; 
import { FigureModalProps } from './props';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutline, Crop, DoDisturbOn, Clear } from '@mui/icons-material';
import getCroppedImg, { convertImageToBlob } from '../../api/crop';
import useStyles from "./css";

const newFigure = new Figure ({
  id: "",
  name: "",
  image: "",
  url: "../../../assets/images/general/img-plus.jpg"
});

const FigureModal = ({ open, figure, isReadOnly, onSaveChanges, onDelete, onClose }: FigureModalProps) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [updatedFigure, setUpdatedFigure] = useState<Figure | null>(figure ? { ...figure } : null);
  const [isNew, setIsNew] = useState<boolean>(false);
  
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [snack, setSnack] = useState(false);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);

  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [urlBlob, setUrlBlob] = useState<string>('');

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [croppedArea, setCroppedArea] = useState(null);
  
  const classes = useStyles();

  // const [cropSize, setCropSize] = useState({ width: 0, height: 0 })
  // const [mediaSize, setMediaSize] = useState({
  //   width: 0,
  //   height: 0,
  //   naturalWidth: 0,
  //   naturalHeight: 0,
  // })

  const onCropComplete = useCallback((croppedAreaPercentages: any, croppedAreaPixels: any) => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  )

  useEffect(() => {
    
    if (figure) {
      setUpdatedFigure(figure);
      setIsNew(false);
    }
    else {
      newFigure.id = uuidv4();
      setUpdatedFigure(newFigure);
      setIsNew(true);
    }

    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setIsWorking(false);

  }, [figure, open]);

  useEffect(() => {
    const disableBtnSave = !(updatedFigure?.name !== "" && updatedFigure?.url !== "" && updatedFigure?.url !== newFigure.url);
    setDisabledSave(disableBtnSave);
  }, [updatedFigure]);

  const handleFigureFieldChange = (fieldName: string, value: any) => {
    if (updatedFigure) {
      setUpdatedFigure((prevFigure: any) => ({
        ...prevFigure,
        [fieldName]: value,
      }));
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);

    if (file) {
      
      const allowedTypes = ['image/png', 'image/jpeg'];
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (!allowedTypes.includes(file.type)) {
        setErrorMessage("Formato no válido. Seleccione un archivo PNG o JPG.");
        setSnack(true);
        return;
      }

      if (!(file.size <= maxSize)) {
        setErrorMessage("Tamaño de archivo no válido. Seleccione un archivo de menos de 10 MB");
        setSnack(true);
        return;
      }

      // setErrorMessage('Invalid file format or size. Please select a PNG or JPG file under 10MB.');

      const url = URL.createObjectURL(file);
      setIsCropping(true);
      setErrorMessage('');
      handleFigureFieldChange("image", file);
      handleFigureFieldChange("url", url);
      setUrlBlob(url);

    }
  };

  const onSave = () => {
    if (onSaveChanges && updatedFigure 
          && updatedFigure.name !== "" 
          &&  updatedFigure.url !== ""  
          && updatedFigure.url !== newFigure.url) {

          onSaveChanges({...updatedFigure, name: updatedFigure.name.trim()});
        }
  }

  const cropOk = async () => {
    
    // urlBlob
    const croppedImage = await getCroppedImg(updatedFigure?.url, croppedArea, rotation);

    convertImageToBlob(croppedImage, "image.jpg")
    .then((file) => {
        handleFigureFieldChange("image", file);

        const url = URL.createObjectURL(file);
        handleFigureFieldChange("url", url);
    })
    .catch((error) => {
        console.error('Error al convertir la imagen a archivo:', error);
    });


    setIsCropping(false);
  }

  const cropCancel = () => {
    setIsCropping(false);
  }

  const handleClose = () => {
    setErrorMessage('');
    setIsCropping(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setIsWorking(false);
    onClose();
  }

  
  const handleDelete = (id: string) => {
    setIsWorking(true);
    onDelete(id);
  }
  
  const handleSave = () => {
    setIsWorking(true);
    onSave();
  }

  return (<>
    <Modal
      open={open} 
      disableEscapeKeyDown={true}
      // onClose={handleClose}
      style={{height: 600}}
      >
      <Card 
        sx={{ 
          maxWidth: 400, 
          margin: 'auto', 
          marginTop: isMobile ? 0 : '10vh',
          height: isMobile && isCropping ? 660 : 620
          }}>
        <CardContent style={{position: "relative"}}>

      
          <Typography variant="h5" style={{margin: "0px auto 10px auto", textAlign: "center"}}>
            Figura
          </Typography>
          
          <Button
              onClick={handleClose} 
              style={{
                  position: "absolute",
                  right: 0,
                  top: 15
              }}>
              <Clear />
          </Button>

          {updatedFigure && (
            <>
              <div key={updatedFigure.id} style={{textAlign: "center", paddingBottom: 20}}>
                
                <div style={{
                  width: "100%", 
                  height: 400, 
                  position: "relative",
                  }}>
                
                  {!isCropping ?
                    <img 
                      src={updatedFigure.image!['type']?.toString().includes('image')! ? URL.createObjectURL(updatedFigure.image!) : updatedFigure.url}
                      alt={updatedFigure.name} 
                      onClick={() => isReadOnly ? null : document.getElementById('fileInput')?.click()} 
                      style={{ 
                        width: 250, 
                        height: 400, 
                        border: "1px solid gray", 
                        borderRadius: 10, 
                        cursor: isReadOnly ? "default" : "pointer" }} 
                    />
                    :
                    <Cropper
                      image={urlBlob}
                      crop={crop}
                      zoom={zoom}
                      rotation={rotation}
                      aspect={2.5 / 4}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      // cropSize={{ width: 375, height: 600 }}
                      // setMediaSize={setMediaSize}
                      // setCropSize={setCropSize} 
                    />}

                </div>

                {/* {errorMessage && <Typography style={{fontSize: 14, color: "red", padding: 10}}>
                  {errorMessage}
                </Typography>} */}

                <input type="file" id="fileInput" onChange={handleImageChange} style={{ display: 'none' }} />
      
              </div>


              {isCropping ? <>
                <div className={classes.controls}>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Zoom:
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.05}
                      aria-labelledby="Zoom"
                      classes={{ root: classes.slider }}
                      onChange={(e, zoom) => setZoom(+zoom)}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Rotación:
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={0.05}
                      aria-labelledby="Rotation"
                      classes={{ root: classes.slider }}
                      onChange={(e, rotation) => setRotation(+rotation)}
                    />
                  </div>
                 
                </div>
                <div style={{textAlign: "right", width: "100%"}}>
                    <Button onClick={() => cropOk()} hidden={isNew} variant="contained"  >
                        <Crop style={{ paddingRight: 10}} /> Recortar
                    </Button> 
                    <Button onClick={() =>  cropCancel()} hidden={isNew} style={{float: "left", color: "black"}} >
                        <DoDisturbOn style={{ paddingRight: 10}} /> No Recortar
                    </Button> 
                </div>
                </>
              : 
                <>
                <div style={{paddingBottom: 20}}>
                  <TextField
                    label="Nombre figura"
                    value={updatedFigure.name}
                    onKeyDown={(e) => {if (e.key === "Enter") onSave()}}
                    onChange={(e) => handleFigureFieldChange('name', e.target.value)}
                    onBlur={(e => {
                      setUpdatedFigure((prev: any) => ({
                        ...prev,
                        name: e.target.value.trim(),
                      }))
                    })}
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                  
                  />
                </div>
                <div style={{textAlign: "right", width: "100%"}}>
                  { !isReadOnly && <>
                  { 
                  !isNew && <Button 
                                onClick={() => handleDelete(updatedFigure.id)} 
                                hidden={isNew}
                                disabled={isWorking}
                                style={{float: "left", color: "black"}} >
                              <DeleteOutline />
                          </Button> 
                  }
                  <Button variant="contained" 
                        onClick={() => handleSave()} 
                        disabled={disabledSave || isWorking} 
                        style={{marginRight: 20}} >
                    Guardar
                  </Button>
                  </>
                  }
                  <Button onClick={handleClose} style={{color: "black"}}>
                    Cerrar
                  </Button>
                </div>
                </>
              }
              <div></div>
            </>
          )}
        </CardContent>
      </Card>
      
    </Modal>

    <Snackbar open={snack} autoHideDuration={6000} onClose={() => setSnack(false)}>
        <Alert
          onClose={() => setSnack(false)}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
  </>);
};

export default FigureModal;







