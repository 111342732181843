// import { createMuiTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const drawerWidth = 540;

// const theme = createMuiTheme({
//     typography: {
//       button: {
//         textTransform: 'none',
//       },
//     },
//   });
  
const useStyles = makeStyles((theme: any) => ({
  
  drawerPaper: {
    width: drawerWidth,
  },
  authButton: {
    textTransform: "none",
    textAlign: "right",
    minWidth: 20
  },

  root: {
    // height: "100vh",
    // backgroundImage: `url(${image})`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],

    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    maxWidth: "500px",
  },
  size: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "410px"
  },

  paper: {
    margin: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: "#fa541c" // theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%"
  },
  drawerUser: {
    padding: 30,
    width: "500px",
    maxWidth: "500px",
    height: '100vh',
    position: "relative",
    textAlign: "center"
  },
  drawerUserMobile: {
    maxWidth: "500px",
    padding: 30,
    width: "100%",
    textAlign: "center"
  },
}));

export default useStyles;