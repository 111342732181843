// import { makeStyles } from "@mui/material/styles";
import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },

}));

export default useStyles;