import {  Box, Container, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Link as LinkMui } from '@mui/material';

import { GitHub } from "@mui/icons-material"; 
import { Url } from "../../constants/url";

export default function Footer() {
    

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      <Link to="/">LoteriApp</Link> &nbsp;
      {new Date().getFullYear()} &nbsp;
      {/* {'•'} &nbsp;•&nbsp; {'luisfbn.dev'} */}
      {/* ©  &nbsp;*/}
    </Typography>
  );
}

  return (<>

  
    <Box
      sx={{
        // display: 'flex',
        justifyContent: 'space-between',
        pt: { xs: 3, sm: 4 },
        width: '100%',
        borderTop: '1px solid',
        borderColor: 'divider',
        mt: 2,
        mb: 4,
        display: "none"
      }}
    >
      
      <div>
        <Link color="text.secondary" to={Url.Faq} >
          FAQs
        </Link>
        <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
          &nbsp;•&nbsp;
        </Typography>
        <Link color="text.secondary" to={"#"} >
          Contacto
        </Link>
        <Copyright />
      </div>
      
      <Stack
        direction="row"
        justifyContent="left"
        spacing={1}
        useFlexGap
        sx={{
          color: 'text.secondary',
        }}
      >
        <IconButton
          color="inherit"
          href="https://github.com/mui"
          aria-label="GitHub"
          sx={{ alignSelf: 'center' }}
        >
          <GitHub />
        </IconButton>

      </Stack>
    </Box>

    {/* <Divider /> */}

    <Box key={"footer"}
        className="no-print" 
        sx={{ 
          py: 6,
          pt: 6, 
          mt: 'auto',
        }}>
      
      <Container maxWidth="lg">


        <div style={{textAlign: "center"}}>
          <Link to={Url.Faq} style={{textDecoration: "none", color:"gray"}} >
            FAQs
          </Link>
          
          <span style={{color:"gray"}} >
            &nbsp;•&nbsp;
          </span>

          <LinkMui href="mailto:loteriapp.soporte@gmail.com" style={{textDecoration: "none", color:"gray"}} >
            Contacto
          </LinkMui>
          
        </div>
        
        <Typography variant="body2" color="text.secondary" align="center">
          <Link color="inherit" to={"/"} style={{textDecoration: "none", color:"gray", fontWeight: "bold"}} >
            LoteriApp {new Date().getFullYear()}
          </Link>
          
        </Typography>

        
        <Typography variant="body2" align="center" style={{textDecoration: "none", color:"gray", fontWeight: "normal"}} >
            <IconButton
              color="inherit"
              target="blank"
              rel="noopener noreferrer"
              href="https://github.com/luisfbn"
              aria-label="GitHub"
              sx={{ marginLeft: -2, display: "none" }}
            >
              
              <GitHub /> 

            </IconButton>
            luisfbn.dev

        </Typography>
        
        {/* style={{backgroundColor: "red"}} */}
        {/* <Link color="inherit" to={"/"} style={{textDecoration: "none", color:"gray", fontWeight: "normal"}} > */}
        {/* </Link> */}
        
        {/*           
          <IconButton
            color="inherit"
            href="https://twitter.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/mui/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton> */}

        {/* <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          <b>luisfbn.dev</b>
        </Typography> */}


          {/* <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link to={"#"} style={{textDecoration: "none", color:"gray"}} >
            Contacto
          </Link> */}

        {/* Copyright
        <Typography variant="body2" color="text.secondary" align="center">
          {'v 1.0.0'}
        </Typography> */}

      </Container>

    </Box>
  </>);
}