import { doc, getDoc, setDoc, updateDoc, arrayUnion, collection, getDocs, query, where  } from "firebase/firestore"; 
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth, db, storage } from "../firebase";

import Figure from "../models/figure";
import Lottery from "../models/lottery";
import { LotteryTypeEnum } from "../models/lottery-type-enum";
import { Url } from "../constants/url";
import LinkModel from "../models/link-model";

const _lotteryDB = "lottery";
const _lotteriesObj = "lotteries";
const _storageFigRef = ref(storage, 'figures');
const _storageLotRef = ref(storage, 'lotteries');

const _newLotteryCloud = {
  id: "0",
  name: "Nube",
  description: "Guarda tus figuras de lotería en la nube.",
  image: "../../assets/images/general/OIG4.jpg",
  url: "",
  type: LotteryTypeEnum.Cloud,
  figures: [],
  isReadOnly: false,

  // Get: async (id:string) => Promise<any>,
  // AddUpdate: async (lottery?: any) => {},
  // GetFigures: async (id:string): Promise<any> => {},
  // AddFigure: async (id:string, fig: Figure): Promise<any> => {},
  // DeleteFigure: async (id:string, idFig: string): Promise<any> => {},
  // DeleteAllFigures: async (id:string): Promise<any> => {},
};


export const cloud0 = new Lottery(_newLotteryCloud); // just to new lotteries
export const cloud = new Lottery(_newLotteryCloud);


// Functions:

cloud.Set = (x: any) => {
  if (typeof x === "object" && x !== null && x !== undefined) {
    cloud.id = x.id;
    cloud.name = x.name;
    cloud.description = x.description;
    cloud.image = x.image;
    cloud.url = `${Url.GameEditCloud}/${x.id}`;
    cloud.type = x.type;
    cloud.figures = x.figures;
    cloud.isReadOnly = x.isReadOnly;
    cloud.idLink = x.idLink;
  }
}


cloud.Get = async (idLottery: string, attempt: number = 0): Promise<any> => {

  const user = auth?.currentUser!;

  if (!user) {
    console.error("no user");
    return;
  }

  const path = `${_lotteryDB}/${user.uid}/${_lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, path);
  const docSnap = await getDoc(lotteryRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // save newLotteryCloud into Firebase
    if (attempt>1)
      return null;
    
    await cloud.AddUpdate();
    return await cloud.Get("0", attempt+1);
  }
}


cloud.Get4Game = async (link: LinkModel): Promise<any> => {

  if (!link.idUser || !link.idLottery) return;

  const path = `${_lotteryDB}/${link.idUser}/${_lotteriesObj}/${link.idLottery}`;
  const lotteryRef = doc(db, path);
  const docSnap = await getDoc(lotteryRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
}


cloud.AddUpdate = async (lotteryParam?: Lottery, userCredential?: any) => {
  const currentUser = auth?.currentUser!;
  const user = userCredential ?? currentUser;
  if (!user) return;
  
  const lottery: any = lotteryParam ?? _newLotteryCloud;

  const pathMain = `${_lotteryDB}/${user.uid}`;
  const lotteryRefMain = doc(db, pathMain);

  const docSnap = await getDoc(lotteryRefMain);

  if (!docSnap.exists()) {
    await setDoc(lotteryRefMain, {
      email: user.email,
      isActive: true
    });
  }


  try {
    
      // add image to storage
      let imageUrl = "";
      const isImageFile = lottery.imageFile! instanceof File;

      if (isImageFile) {
        // delete imageStorage
        // is not image url from firebase
        const isImageLocal = lottery.image.toLowerCase().includes("blob");

        if (!isImageLocal) {
          const imageRef = ref(_storageLotRef, lottery.image);
          await deleteObject(imageRef);
        }
        
        const imgName = `${user.uid}_${Date.now()}_${lottery?.imageFile!.name}`;
        imageUrl = await uploadImageToStorage(lottery?.imageFile!, imgName, _storageLotRef);
      }
      
      // add or update the doc of lottery in lotteries
      // colls  /docs  /colls    /docs/lottery.figures: []
      // lottery/userId/lotteries/0   /lottery.figures: []
      const path = `${_lotteryDB}/${user.uid}/${_lotteriesObj}/${lottery.id}`;
      const lotteryRef = doc(db, path);

      const docLotSnap = await getDoc(lotteryRef);

      if (docLotSnap.exists()) {
        // update
        if (isImageFile && imageUrl) {
          const fbDoc = convertToFirebaseLottery({ ...lottery, image: imageUrl });
          await updateDoc(lotteryRef, fbDoc);
        } else {
          const fbDoc = convertToFirebaseLottery({ ...lottery, url: "" });
          await updateDoc(lotteryRef, fbDoc);
        }

      } else {
        // new
        const firebaseDoc = convertToFirebaseLottery({
          ...lottery,
          image: imageUrl,
          figures: [],
          url: ""
        });
        await setDoc(lotteryRef, firebaseDoc);

      }

  }
  catch (error: any) {
    console.error(error);
  }
}

const convertToFirebaseLottery = (x: any) => {
  return {
    id: x.id,
    name: x.name || "",
    description: x.description || "",
    image: x.image || "",
    figures: x.figures || [],
    // url: x.url || "",
    isReadOnly: false,
    type: 2,
  }
}


cloud.GetFigures = async (idLottery: string, idUser?: string) => {
  const uid = idUser || auth?.currentUser!.uid!;
  if (!uid) return;
  
  const path = `${_lotteryDB}/${uid}/${_lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, path);
  const docSnap = await getDoc(lotteryRef);

  if (docSnap.exists()) {
    return docSnap.data()?.figures || [];
  } else {
    return [];
  }

}


const uploadImageToStorage = async (file: File, imgName: string, storageRef: any = _storageFigRef) => {
  
  const imageRef = ref(storageRef, imgName);
  const uploadTask = uploadBytesResumable(imageRef, file);

  return new Promise<string>(async (resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error(error);
        reject("");
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        resolve(url);
      }
    );
  });
};


cloud.AddUpdateFigure = async (idLottery: string, fig: Figure) => {
  const user = auth?.currentUser!;
  if (!user) return [];

  const path = `${_lotteryDB}/${user.uid}/${_lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, path);
  const docSnap = await getDoc(lotteryRef);

  if (!docSnap.exists()) {
    console.error('Lottery not found.');
    return [];
  }

  const lottery = docSnap.data();

  let figs: Figure[] = [];
  figs = lottery.figures || [];

  const index = figs.findIndex((x: Figure) => x.id === fig.id);
  const isNew = index === -1;
  const isImageFile = fig.image! instanceof File;

  if (!isNew && isImageFile) {
    // delete imageStorage
    const imgNameMod = lottery.figures[index].image;
    const imageRef = ref(_storageFigRef, imgNameMod);
    await deleteObject(imageRef);
  } 
  
  let figFirebase: any = fig;

  // add imageStorage
  if (isImageFile) {
    const imgName = `${user.uid}_${Date.now()}_${fig?.image!.name}`;
    const url = await uploadImageToStorage(fig.image!, imgName);

    figFirebase = {
      ...fig,
      url: url,
      image: imgName!
    };
  }

  // update store
  if (isNew) {
    await updateDoc(lotteryRef, {
      figures: arrayUnion(figFirebase),
    });
    figs.push(new Figure(figFirebase));

  } else {
    figs[index] = figFirebase;
    await updateDoc(lotteryRef, {
      figures: figs,
    });

  }

  return figs;

}


cloud.DeleteFigure = async (id: string, idFigure: string) => {
  const user = auth?.currentUser!;
  if (!user) return [];
  
  const lotteryRef = doc(db, `${_lotteryDB}/${user.uid}/${_lotteriesObj}/${id}`);
  const docSnap = await getDoc(lotteryRef);

  let lottery = docSnap.data();

  if (lottery) {

    const index = lottery.figures.findIndex((x: any) => x.id === idFigure);
    const isNew = index === -1;
    
    if (isNew) {
      console.error('Figure not found.');
      return lottery.figures;
    }

    const imgName = lottery.figures[index].image;
    // Borrar la imagen de Firebase Storage
    const imageRef = ref(_storageFigRef, imgName);
    await deleteObject(imageRef);
    
    lottery.figures.splice(index, 1);

    await updateDoc(lotteryRef, {
      figures: lottery.figures
    });
    
    return lottery.figures;

    
  } else {
    console.error('Lottery not found.');
    return [];
  }

}


cloud.DeleteAllFigures = async (id: string) => {
  const user = auth?.currentUser!;
  if (!user) return [];

  const lotteryRef = doc(db, `${_lotteryDB}/${user.uid}/${_lotteriesObj}/${id}`);
  const docSnap = await getDoc(lotteryRef);

  if (docSnap.exists()) { 

    const lottery = docSnap.data();

    lottery.figures?.forEach(async (fig: any) => {

      try {
        const imageRef = ref(_storageFigRef, fig.image);
        await deleteObject(imageRef);
      }
      catch (e: any) {
        console.error(e);
      }
      
    });

    await updateDoc(lotteryRef, {
      figures: [],
    });

  } else {
    console.error('Lottery not found.');
  }
  
  return [];
}


cloud.GetLotteries = async (userId: string) => {

  try {
    const path = `${_lotteryDB}/${userId}/${_lotteriesObj}`;

    const lotteriesCollection = collection(db, path);
    
    // const querySnapshot = await getDocs(lotteriesCollection);

    const querySnapshot = await getDocs(
      query(lotteriesCollection, where('id', '!=', '0'))
    );

    const lotteriesData = querySnapshot.docs.map(doc => ({
      docId: doc.id,
      name: doc.data()?.name!,
      ...doc.data()
    }));
  
    const ordered = lotteriesData
                    .sort((a, b) => {
                      //a.name.localeCompare(b.name, 'es', { sensitivity: 'base' })
                      if (!a.name && !b.name) return 0; // Si ambos nombres están vacíos, son iguales
                      if (!a.name) return 1; // Si solo el nombre de 'a' está vacío, 'b' va primero
                      if (!b.name) return -1; // Si solo el nombre de 'b' está vacío, 'a' va primero
                    
                      return a.name.localeCompare(b.name, 'es', { sensitivity: 'base' });
                    });

    return ordered;

  } catch (error) {
    console.error("Error fetching lotteries: ", error);
    throw error;
  }

}



export default cloud;