import { useEffect, useRef, useState } from "react";
import { Backdrop, Button, Card, CardContent, CircularProgress, Container, Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DeleteForever, LocalPrintshop } from "@mui/icons-material";
import Swal from "sweetalert2";

import Figure from '../../models/figure'; 
import FigureModal from "../FigureModal";
import FigureAdd from "./figure-add";

import { FiguresProps } from "./props";
import useStyles from "./css";
import ImageLoader from "../ImageLoader";

// import { useReactToPrint } from 'react-to-print';
// const html2pdf = require('html2pdf.js');

export default function Figures (props?: FiguresProps | null) {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();
    const [figures, setFigures] = useState<Figure[]>([]);

    const [openModal, setOpenModal] = useState(false);
    const [selectedFigure, setSelectedFigure] = useState<Figure | null>(null);
    const [printing, setPrinting] = useState(false)

    const [backDrop, setBackDrop] = useState(true);
    const [loading, setLoading] = useState(true);
    
      const componentRef = useRef(null);

    useEffect(() => {

        if (props?.figures) {
            setFigures(props?.figures)
            setBackDrop(false);
            setLoading(false)
        }

    }, [props?.figures]); // images

    const handleOpenModal = (figure: Figure) => {
        setSelectedFigure(figure);
        setOpenModal(true);
    };

    const handleCloseModal = (
        event?: {},
        reason?: string
      ) => {

        if (reason !== "backdropClick") {
            setOpenModal(false);
            setSelectedFigure(null);
        }

    };

    const handleSaveChanges = (updatedFigure: Figure) => {
        setBackDrop(true);
        props?.add(updatedFigure).then((newFigures) => {
            // setFigures(newFigures);
            setBackDrop(false);
            handleCloseModal();
        });
    };

    
    const handleDelete = (id: string) => {
        setBackDrop(true);
        props?.delete(id).then((newFigures) => {
            setFigures(newFigures);
            setBackDrop(false);
            handleCloseModal();
        });
    };

    

    const handleDeleteAllAlert = () => {

        Swal.fire({
            title: 'Confirmar',
            text: 'Se eliminarán todas las imágenes de la lotería.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#fa541c',
            cancelButtonColor: '#ccc',
            confirmButtonText: 'Sí, eliminar lotería',
            cancelButtonText: 'Cancelar',
        }).then((result: any) => {
            if (result.isConfirmed) {
                setBackDrop(true);
                props?.deleteFiguresAll().then(() => {
                    setFigures([]);
                    setBackDrop(false);
                });
        
            }
        });
        
    };
        
    // const handlePdf = (useReactToPrint)({
        
    //     onPrintError: (error) => {
    //     setBackDrop(false);
    //     },
    //     content: () => componentRef.current,
    //     removeAfterPrint: true,
    //     print: async (printIframe) => {
    //     const document = printIframe.contentDocument;
    //     if (document) {
    //         const html = document.getElementById("div-figures");

    //         // https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
    //         var opt = {
    //         margin:       1, //[10, 10, 10, 10],
    //         filename:     'baraja.pdf',
    //         image:        { type: 'jpeg', quality: 0.99 },
    //         html2canvas:  { scale: 1 },
    //         jsPDF:        { format: 'letter', orientation: 'portrait', unit: 'in' } //  , unit: 'in'px
    //         };

    //         // html2pdf(html);
    //         html2pdf().set(opt).from(html).save();
    //         // html2pdf().from(html).save();
    //         setTimeout(() => setBackDrop(false), 2000);
    //         }
    //     },

    // });

    
    const handlePrint = () => {

        setPrinting(true);
    
        setTimeout(() => {
            window.print();
            setPrinting(false);
            },
            1000);
    };


    return (<>
    
        <div className="no-print" style={{width: "100%", textAlign: "right", marginBottom: 50}}>
        
            {!props?.isReadOnly && <>
            <Tooltip title="Eliminar Todo" style={{marginRight: 20}} >
                <Button 
                    disabled={figures?.length!<1}  
                    onClick={() => { 
                        handleDeleteAllAlert(); 
                        }} >
                    <DeleteForever />
                </Button>
            </Tooltip>
            
            </>}
            
            <Tooltip title={printing ? "" : "Imprimir"} arrow>
                <Button disabled={printing} onClick={() => {handlePrint()}}>
                <LocalPrintshop />
                </Button> 
            </Tooltip>

            {/* <Tooltip title="Descargar en PDF" arrow>
                <IconButton onClick={() => { setBackDrop(true); handlePdf();}}>
                    <PictureAsPdf />
                </IconButton>
            </Tooltip> */}
        
        </div>
    
    
    <Container component="main"  style={{padding: 0}} >

        <Grid container spacing={2} 
                    id="div-figures"
                    key="div-figures"
                    ref={componentRef} >

            {figures?.map((figure) => (
                <Grid item 
                    key={figure.id} 
                    className={classes.printGridWrapper}
                    xs={6} sm={4} md={3} lg={2}
                >
                    <Card 
                        onClick={() => handleOpenModal(figure)}
                        className={classes.printCardWrapper}
                    >

                        <ImageLoader
                            src={ figure.image!['type']?.toString().includes('image')! ? URL.createObjectURL(figure.image!) : figure.url } 
                            alt={figure.name} 
                            className={isMobile ? classes.imgMobile : classes.img}
                            loaderWidth={165}
                            loaderHeight={250}
                            // style={{width: isMobile ? 140 : 155 }}
                        />

                        <CardContent>
                            <Typography className={classes.printTextWrapper} style={{margin: "auto"}}>
                                {figure.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}

            {
                !props?.isReadOnly && !loading && figures?.length! < 56 && <FigureAdd openModal={() => setOpenModal(true)} />
            }

        </Grid>
    </Container>
    
    <FigureModal
        open={openModal}
        figure={selectedFigure}
        isReadOnly={props?.isReadOnly!}
        onSaveChanges={handleSaveChanges}
        onDelete={handleDelete}
        onClose={handleCloseModal}
    />

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDrop}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
    </>);
}