import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Esto asegura que el contenido ocupe toda la altura del Grid item
  },

}));

export default useStyles;