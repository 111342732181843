import React from 'react';
import Confetti from 'react-confetti';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Url } from '../../constants/url';

const Success = () => {

  return (<>
      <Container maxWidth="sm" style={{ textAlign: 'center' }}>

        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false} 
        />

        <Typography variant="h4" pt={30}>
          🎉 Gracias por tu compra
        </Typography>

         <Button
          component={Link} 
          to={Url.Dashboard}
          color="secondary"
          size="large"
          variant="contained"
          sx={{ mt: 5 }}
          style={{ backgroundColor: '#fa541c' }}
        >
          Volver
        </Button>
      </Container>
    
  </>);
};

export default Success;
