import ReactGA from "react-ga";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

const useAnalyticsEventTracker = (category: string = "LoteriApp") => {
  const eventTracker = (action: string = "", label: string = "") => {
    
    const event = {category, action, label};
    
    ReactGA.event(event);

    logEvent(analytics, action)
    
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;