import { createTheme } from "@mui/material";


export const ThemeStyles = createTheme({
    palette: {
      primary: {
        main: "#fa541c", // #637381", //#6462F3",
        light: "#a697df",
      },
    },
  });