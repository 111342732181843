// https://github.com/reduxjs/redux-toolkit/issues/587

/* Core */
// import { Action, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'; // , useSelector, TypedUseSelectorHook

/* Instruments */
// import { rootReducer } from '../rootReducer';
import store from './store';
import authReducer from '../auth/auth-slice';

// export type ReduxState = ReturnType<typeof authReducer>;
export type AuthStateType = ReturnType<typeof authReducer>;

export type TypedDispatch = typeof store.dispatch;
// export type TypedThunk<R = void> = ThunkAction<R, ReduxState, unknown, Action>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
// export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;