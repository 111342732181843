import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  body: {
    // width: '95vw',
    height: '100vh',
    position: "relative",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 50,
    margin: "auto"
  }

}));

export default useStyles;