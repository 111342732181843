import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Backdrop, CircularProgress, Container, IconButton } from '@mui/material';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import Figures from '../Figures';
import Tables from '../Tables';
import { CustomTabPanel } from './custom-tab-panel';
import Lottery from '../../models/lottery';
import Figure from '../../models/figure';

import { classic } from "../../api/classic";
import { local } from "../../api/local";
import { cloud } from "../../api/cloud";
import GameTables from '../GameTables';
import GameCards from '../GameCards';
import useStyles from "./css";
import { LotteryTypeEnum } from '../../models/lottery-type-enum';
import GameLink from '../GameLink/game-link';
import { ArrowBack, ArrowForwardIos } from '@mui/icons-material';


function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function GameEdit() {

  const { user } = useSelector((state: RootState) => state.auth);
  const [valueTab, setValueTab] = useState(0);
  const [idLottery, setIdLottery] = useState<string>("0");
  const [lottery, setLottery] = useState<Lottery>();
  const [isTableOk, setIsTableOk] = useState<boolean>(false);
  const [backdrop, setBackdrop] = useState(true);

  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();


  useEffect(() => {
    
    setIdLottery(params?.id!)

    if (params.type === "classic") {
      loadClassic();
    }
    
    if (params.type === "local") {
      loadLocal();
    }

    if (params.type === "cloud") {
      if (user) {
        loadCloud(params?.id!);
      }else {
        navigate('/');
      }
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, user]);

  const loadClassic = () => {
    classic.GetFigures(idLottery).then((figs) => {
      classic.figures = figs;
      setLottery(classic);
      setIsTableOk(figs.length<16);
      setBackdrop(false);
    });
  }

  const loadLocal = () => {
    local.GetFigures(idLottery).then((figs) => {
      local.figures = figs;
      setLottery(local);
      setIsTableOk(figs.length<16);
      setBackdrop(false);
    });
  }

  const loadCloud = (id?: string) => {
    
    cloud.Get(id ?? idLottery).then(data => {
      cloud.Set(data);
      cloud.GetFigures(id ?? idLottery).then((figs) => {
        cloud.figures = figs;
        setLottery(cloud);
        
        if (figs)
          setIsTableOk(figs?.length!<16);

      setBackdrop(false);
      });
    });
  }

  useEffect(() => {
    setIsTableOk(lottery?.figures.length!<16);
  }, [lottery])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };



  const handleAdd = async (fig: Figure) => {
    setBackdrop(true);

    lottery?.AddUpdateFigure(idLottery, fig).then(newFigures => {

      let lott = {
        ...lottery, 
        figures: newFigures
      };

      setLottery(lott);
      setIsTableOk(newFigures!.length<16);
      
      return newFigures!;

    })
    .catch((error: any) => {
      console.error(error);
      return [];
    })
    .finally(() => {
      setBackdrop(false)
    });
  }

  const handleDelete = async (idFigure: string) => {
    const newFigures = await lottery?.DeleteFigure(idLottery, idFigure);
    
    let lott = lottery;
    if (lott && lott.figures) {
      lott.figures = newFigures!;
      setLottery(lott);
      setIsTableOk(newFigures!.length<16);
    }

    return newFigures!;
  }

  const handleDeleteAll = async () => {
    const newFigures = await lottery?.DeleteAllFigures(idLottery);
    
    let lott = lottery;
    if (lott && lott.figures) {
      lott.figures = newFigures!;
      setLottery(lott);
      setIsTableOk(newFigures!.length<16);
    }

    return newFigures!;
  }

  return (<>
    <Container component="main" sx={{mt: 2, mb: 2}} className={classes.color} >
          
      <Box marginBottom={2} className="no-print">
          <Typography variant="h5" >
            <Link to={'/dashboard'} style={{textDecoration: "none", color: "inherit", fontWeight: "bold"}}>
            <IconButton
              color="inherit"
              aria-label="GitHub"
              sx={{ marginLeft: -1, marginTop: "-5px" }}
            >
              
              <ArrowBack />

            </IconButton>
              Juegos de Lotería
            </Link>
            
            {lottery && lottery.name!
                ? <ArrowForwardIos style={{fontSize: 14, marginLeft: 10, marginRight: 10}} /> 
                : ""}
            {lottery ? lottery?.name! : ""}
          </Typography>
      </Box>

      <Typography key="desc-lottery" className="no-print" variant="h6" style={{marginTop: -5, marginBottom: 20}}>
          {lottery ? lottery?.description : "Cargando..."}
      </Typography>

      <Box sx={{ borderBottom: 0, borderColor: 'divider' }} className="no-print">

        <Tabs 
            value={valueTab} 
            onChange={handleChangeTab} 
            scrollButtons="auto" 
            variant="scrollable"
            aria-label="basic tabs"
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: 5
            }}
        >

          <Tab label="Figuras" {...tabProps(0)} />
          <Tab label="Imprimir" {...tabProps(1)} disabled={isTableOk}  />
          
          <Tab label="Barajear" {...tabProps(2)} disabled={isTableOk}  />
          <Tab label="Jugar" {...tabProps(3)} disabled={isTableOk}  />
          
          <Tab label="Compartir" {...tabProps(4)} 
                disabled={isTableOk || lottery?.type! === LotteryTypeEnum.Local} 
                style={{display: lottery?.type! === LotteryTypeEnum.Local ? "none" : ""}} />

        </Tabs>
      </Box>

      <CustomTabPanel value={valueTab} index={0}  >
        <Figures 
          figures={lottery?.figures} 
          add={handleAdd!} 
          delete={handleDelete!} 
          deleteFiguresAll={handleDeleteAll!}
          isReadOnly={lottery?.isReadOnly!} 
        />
      </CustomTabPanel>

      <CustomTabPanel value={valueTab} index={1}>
        <Tables
          lottery={lottery}
          figures={lottery?.figures!} 
        />
      </CustomTabPanel>

      <CustomTabPanel value={valueTab} index={2}>
        <GameCards 
          lottery={lottery}
          figures={lottery?.figures!} 
          />
      </CustomTabPanel>

      <CustomTabPanel value={valueTab} index={3}>
        
        <GameTables 
          lottery={lottery}
          figures={lottery?.figures!} 
          />

      </CustomTabPanel>

      
      {lottery?.type !== LotteryTypeEnum.Local && <>
        <CustomTabPanel value={valueTab} index={4}>
          <GameLink lottery={lottery} />
        </CustomTabPanel>
      </>}

    </Container>


    

    <Backdrop
        className='no-print'
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="inherit" />
    </Backdrop>
</>);

}