import React, { useState, useEffect, useMemo } from 'react';
import { useMediaQuery, useTheme, } from '@mui/material';

import Figure from '../../models/figure'; 
import figuresJson from '../../api/original.json';
import ImgFigures from '../../assets/images/figures';
import { TableProps } from './props';
import ImageLoader from '../ImageLoader/image-loader';
import useStyles from "./css";

const Table = (props: TableProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [figures, setFigures] = useState<Figure[]>([]);
  const [figuresData, setFiguresData] = useState<Figure[]>([]);

  const images = useMemo(async () => await ImgFigures, []);

  useEffect(() => {
    
    if (props.figures) {
      setFiguresData(props.figures);
    }
    else {
      images.then(x => {
        let figs: Figure[] = [];
        figuresJson.forEach((fig, i) => {
          const f = new Figure(fig);
          f.url = x[i];
          figs.push(f);
        });
  
        setFiguresData(figs);
      });
    }

  }, [images, props.figures]);

  useEffect(() => {

    const randomizeFigures = () => {
        const shuffledFigures = shuffleArray(figuresData);
        setFigures(shuffledFigures.slice(0, 16));
      };
    
    randomizeFigures();

  }, [figuresData]);


  const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const chunkArray = (array: any[], size: number) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };
  
  return (<>

  <div className={classes.printWrapper} 
    style={{ 
          ...props.style,
          pageBreakAfter: props.index+1 < props.tablesSelected ? 'always' : 'auto', 
          margin: isMobile ? "auto 0px 40px auto" 
                           : (props.modeGame ? "auto 70px 40px auto" : "auto 40px 40px auto")
          }}>
    <table style={{border: "2px solid black", minWidth: 300}} className={classes.printTableWrapper}>
        <tbody>
            {/* {!figures?.length!
                    ? <tr>
                      <td>
                    <Skeleton 
                      animation="pulse" 
                      variant="rectangular" 
                      width={340} height={530} /> 
                      </td>
                    </tr>
                    : <> */}
            {chunkArray(figures, 4).map((row, rowIndex) => (
            <tr key={rowIndex}>
                {row.map((figure, columnIndex) => (
                <td key={columnIndex} style={{border: "1px solid gray"}}>
                    <ImageLoader 
                      src={figure.image!['type']?.toString().includes('image')! ? URL.createObjectURL(figure.image!) : figure.url}
                      alt={figure.name} 
                      className={classes.printImg} 
                      modeGame={props.modeGame}
                      cleanGame={props.cleanGame}
                      />
                    {props.showName && <>
                      <div 
                        style={{
                          backgroundColor: "white",
                          width: "100%",
                          position: "relative",
                          bottom: 0,
                          textAlign: "center",
                          fontSize: 8,
                          fontWeight: "bold",
                        }} >
                        {figure.name}
                      </div>
                    </>}
                </td>
                ))}
            </tr>
            ))}
            {/* </>} */}
        </tbody>
    </table>
  </div>

</>)};


export default Table;

