import { deleteDoc, doc, getDoc, setDoc, updateDoc  } from "firebase/firestore"; 
import { auth, db } from "../firebase";
import LinkModel from "../models/link-model";

const linkDB = "link";
const lotteryDB = "lottery";
const lotteriesObj = "lotteries";

export const Get = async (idLink: string) => {
  
  if (!idLink) return null;

  const path = `${linkDB}/${idLink}`;
  const linkRef = doc(db, path);
  const docSnap = await getDoc(linkRef);

  if (docSnap.exists()) {

    const data = docSnap.data();
    return new LinkModel(data);

  } else {
    return null;
  }

}


export const GetIdLinkByLottery = async (idLottery: string) => {
  
  const user = auth?.currentUser!;

  if (!user) {
    console.error("no user");
    return;
  }

  const pathLottery = `${lotteryDB}/${user.uid}/${lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, pathLottery);
  const docSnap = await getDoc(lotteryRef);

  if (docSnap.exists()) {

    const data = docSnap.data();
    return data.url;

  } else {
    return null;
  }

}


export const AddUpdate = async (idLink: string, idLottery: string, userCredential?: any) => {
  const currentUser = auth?.currentUser!;
  const user = userCredential || currentUser;
  if (!user) return;
  
  const path = `${linkDB}/${idLink}`;
  const linkRef = doc(db, path);

  const pathLottery = `${lotteryDB}/${user.uid}/${lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, pathLottery);

  let link: LinkModel = {
    id: idLink,
    idUser: user.uid,
    idLottery: idLottery,
    expire: null,
    isActive: true
  }

  try {
    await setDoc(linkRef, link);
    
    await updateDoc(lotteryRef, {
      url: link.id,
    });

    return link;
  }
  catch (error: any) {
    console.error(error);
    return null;
  }
}


export const Delete = async (idLink: string, idLottery: string) => {
  const user = auth?.currentUser!;
  if (!user) return;
  
  const path = `${linkDB}/${idLink}`;
  const linkRef = doc(db, path);

  const pathLottery = `${lotteryDB}/${user.uid}/${lotteriesObj}/${idLottery}`;
  const lotteryRef = doc(db, pathLottery);

  try {
    // await updateDoc(linkRef, {isActive: false});
    await deleteDoc(linkRef);
    await updateDoc(lotteryRef, {
      url: "",
    });

    return true;
  }
  catch (error: any) {
    console.error(error);
    return false;
  }
}


const CloudLink = { 
  Get, GetIdLinkByLottery, AddUpdate, Delete
}

export default CloudLink;







