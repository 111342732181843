import { Url } from "../constants/url";
import { LotteryTypeEnum } from "../models/lottery-type-enum";
import Lottery from "../models/lottery";
import { v4 as uuidv4 } from 'uuid';
import Figure from "../models/figure";

import ImgFigures from '../assets/images/figures';
import figuresData from './original.json';

const newLotteryClassic = {
  id: uuidv4(),
  name: "Clásica",
  description: "Lotería original para jugar todo un clásico.",
  image: "../../assets/images/general/card1.png",
  url: Url.GameEditClassic,
  type: LotteryTypeEnum.Classic,
  figures: [],
  isReadOnly: true
}

export const classic: Lottery = new Lottery(newLotteryClassic);


classic.GetFigures = async (id:string) => {
  let images = await ImgFigures;
  classic.figures = [];

  figuresData.forEach((figureData, index) => {
    const figure = new Figure(figureData);
    figure.url = images[index];
    classic.figures.push(figure);
  });

  return classic.figures;
}

classic.AddUpdateFigure = async (id:string, image: Figure) => {
    return classic.figures;
}

classic.DeleteFigure = async (id:string, idFigure: string) => {
    return classic.figures;
}

classic.DeleteAllFigures = async (id:string) => {
  return classic.figures;
}