export default class LinkModel {
    id: string;
    idUser: string;
    idLottery: string;
    expire: Date | null;
    isActive: boolean;
    
    constructor(x?: any) {
        if (typeof x === "object" && x !== null && x !== undefined) {
            this.id = x.id;
            this.idUser = x.idUser;
            this.idLottery = x.idLottery;
            this.expire = x.expire;
            this.isActive = x.isActive;
        }
        else {
            this.id = "";
            this.idUser = "";
            this.idLottery = "";
            this.expire = null;
            this.isActive = false;
        }
    }
}

