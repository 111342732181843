import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Card, CardContent, Typography, Button, TextField, Slider, useMediaQuery, useTheme, Alert, Snackbar } from '@mui/material';
import Cropper from 'react-easy-crop'; // , { getInitialCropFromCroppedAreaPercentages }
import { Crop, DoDisturbOn, Clear } from '@mui/icons-material';

// import Figure from '../../models/figure'; 
import { FigureModalProps } from './props';
import getCroppedImg, { convertImageToBlob } from '../../api/crop';
import Lottery from '../../models/lottery';
import useStyles from "./css";


const newLottery = new Lottery ({
  id: "",
  name : "",
  description : "",
  image : "",
  imageFile: undefined,
  url : "",
  type: undefined,
  figures: [],
  isReadOnly: true,
  idLink : ""
});

const LotteryModal = ({ open, lottery, isReadOnly, onSaveChanges, onClose }: FigureModalProps) => {
  const imageUrlDefault = "../../assets/images/general/img-plus-2.jpg";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [updatedLottery, setUpdatedLottery] = useState<Lottery | null>(lottery ? { ...lottery } : null);
  const [isNew, setIsNew] = useState<boolean>(false);
  
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [snack, setSnack] = useState(false);

  const [disabledSave, setDisabledSave] = useState<boolean>(true);

  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [urlBlob, setUrlBlob] = useState<string>('');

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [croppedArea, setCroppedArea] = useState(null);
  
  const classes = useStyles();

  const onCropComplete = useCallback((croppedAreaPercentages: any, croppedAreaPixels: any) => {
      setCroppedArea(croppedAreaPixels);
    },
    []
  )

  useEffect(() => {
    
    if (lottery) {
      if (lottery.image === "")
        lottery.image = newLottery.image;
      setUpdatedLottery(lottery);
      setIsNew(false);
    }
    else {
      setUpdatedLottery(newLottery);
      setIsNew(true);
    }

    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setIsWorking(false);

  }, [lottery, open]);

  useEffect(() => {
    const disableBtnSave = !(updatedLottery?.name !== "" && updatedLottery?.image !== "" && updatedLottery?.image !== newLottery.image);
    setDisabledSave(disableBtnSave);
  }, [updatedLottery]);

  const handleLotteryFieldChange = (fieldName: string, value: any) => {
    if (updatedLottery) {
      setUpdatedLottery((prevFigure: any) => ({
        ...prevFigure,
        [fieldName]: value,
      }));
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);

    if (file) {
      
      const allowedTypes = ['image/png', 'image/jpeg'];
      const maxSize = 10 * 1024 * 1024; // 10MB

      if (!allowedTypes.includes(file.type)) {
        setErrorMessage("Formato no válido. Seleccione un archivo PNG o JPG.");
        setSnack(true);
        return;
      }

      if (!(file.size <= maxSize)) {
        setErrorMessage("Tamaño de archivo no válido. Seleccione un archivo de menos de 10 MB");
        setSnack(true);
        return;
      }

      // setErrorMessage('Invalid file format or size. Please select a PNG or JPG file under 10MB.');

      const url = URL.createObjectURL(file);
      setIsCropping(true);
      setErrorMessage('');
      handleLotteryFieldChange("imageFile", file);
      handleLotteryFieldChange("image", url);
      setUrlBlob(url);

    }
  };

  const onSave = () => {
    if (onSaveChanges && updatedLottery 
          && updatedLottery.name !== "" 
          &&  updatedLottery.image !== ""  
          && updatedLottery.image !== newLottery.image) {

          onSaveChanges({...updatedLottery, name: updatedLottery.name.trim()});
        }
  }

  const cropOk = async () => {
    
    // urlBlob
    const croppedImage = await getCroppedImg(updatedLottery?.image, croppedArea, rotation);

    convertImageToBlob(croppedImage, "image.jpg")
    .then((file) => {
        handleLotteryFieldChange("imageFile", file);

        const imageUrl = URL.createObjectURL(file);
        handleLotteryFieldChange("image", imageUrl);
    })
    .catch((error) => {
        console.error('Error al convertir la imagen a archivo:', error);
    });


    setIsCropping(false);
  }

  const cropCancel = () => {
    setIsCropping(false);
  }

  const handleClose = () => {
    setErrorMessage('');
    setIsCropping(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setIsWorking(false);
    onClose();
  }

  
  const handleSave = () => {
    setIsWorking(true);
    onSave();
  }

  return (<>
    <Modal
      open={open} 
      disableEscapeKeyDown={true}
      // onClose={handleClose}
      // style={{height: isMobile ? 380 : 480}}
      >
      <Card 
        sx={{ 
          maxWidth: 450, 
          margin: 'auto', 
          marginTop: isMobile ? 0 : '10vh',
          // backgroundColor: "red",
          height: isMobile 
                      ? (isCropping ? 520 : 550) 
                      : (isCropping ? 500 : 600)
          }}>
        <CardContent style={{position: "relative", padding: 22}}>

          <Typography variant="h5" style={{margin: "0px auto 10px auto", textAlign: "center"}}>
            Lotería
          </Typography>
          
          <Button
              onClick={handleClose} 
              style={{
                  position: "absolute",
                  right: 0,
                  top: 15
              }}>
              <Clear />
          </Button>

          {updatedLottery && (
            <>
              <div key={updatedLottery.id} 
                    style={{
                      textAlign: "center", 
                      width: "100%", 
                      height: isMobile ? 250 : 300,
                      position: "relative",
                    }}>
                
                  {!isCropping ?
                    <img 
                      src={ ((updatedLottery.imageFile && updatedLottery.imageFile['type']?.toString().includes('image'))
                                ? URL.createObjectURL(updatedLottery.imageFile!) 
                                : updatedLottery.image)

                            || imageUrlDefault
                          }
                      alt={updatedLottery.name} 
                      onClick={() => isReadOnly ? null : document.getElementById('fileInput')?.click()} 
                      style={{ 
                        height: isMobile ? 230 : 280,
                        width: "100%",
                        border: "1px solid gray", 
                        borderRadius: 10, 
                        cursor: isReadOnly ? "default" : "pointer" }} 
                    />
                    :
                    <Cropper
                      image={urlBlob}
                      crop={crop}
                      zoom={zoom}
                      rotation={rotation}
                      aspect={4 / 3}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />}

                <input type="file" id="fileInput" onChange={handleImageChange} style={{ display: 'none' }} />
      
              </div>


              {isCropping 
              ? <>
                <div className={classes.controls}>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Zoom:
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.05}
                      aria-labelledby="Zoom"
                      classes={{ root: classes.slider }}
                      onChange={(e, zoom) => setZoom(+zoom)}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Rotación:
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={0.05}
                      aria-labelledby="Rotation"
                      classes={{ root: classes.slider }}
                      onChange={(e, rotation) => setRotation(+rotation)}
                    />
                  </div>
                 
                </div>
                <div style={{textAlign: "right", width: "100%"}}>
                    <Button onClick={() => cropOk()} hidden={isNew} variant="contained"  >
                        <Crop style={{ paddingRight: 10}} /> Recortar
                    </Button> 
                    <Button onClick={() =>  cropCancel()} hidden={isNew} style={{float: "left", color: "black"}} >
                        <DoDisturbOn style={{ paddingRight: 10}} /> No Recortar
                    </Button> 
                </div>
                </>
              
              : <>
                <div style={{paddingBottom: 15, marginTop: 15, textAlign: "center"}}>
                  <TextField
                    label="Título de la lotería"
                    value={updatedLottery.name}
                    onKeyDown={(e) => {if (e.key === "Enter") onSave()}}
                    onChange={(e) => handleLotteryFieldChange('name', e.target.value)}
                    onBlur={(e => {
                      setUpdatedLottery((prev: any) => ({
                        ...prev,
                        name: e.target.value.trim(),
                      }))
                    })}
                    fullWidth
                    inputProps={{ maxLength: 15 }}
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                  
                  />
                </div>
                
                <div style={{paddingBottom: 25}}>
                  <TextField
                    label="Descripción"
                    value={updatedLottery.description}
                    onKeyDown={(e) => {if (e.key === "Enter") onSave()}}
                    onChange={(e) => handleLotteryFieldChange('description', e.target.value)}
                    onBlur={(e => {
                      setUpdatedLottery((prev: any) => ({
                        ...prev,
                        description: e.target.value.trim(),
                      }))
                    })}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                  
                  />
                </div>

                <div style={{textAlign: "right", width: "100%"}}>
                  { !isReadOnly && <>
                  <Button variant="contained" 
                        onClick={() => handleSave()} 
                        disabled={disabledSave || isWorking} 
                        style={{marginRight: 20}} >
                    Guardar
                  </Button>
                  </>
                  }
                  <Button onClick={handleClose} style={{color: "black"}}>
                    Cerrar
                  </Button>
                </div>
                </>
              }
              <div></div>
            </>
          )}
        </CardContent>
      </Card>
      
    </Modal>

    <Snackbar open={snack} autoHideDuration={6000} onClose={() => setSnack(false)}>
        <Alert
          onClose={() => setSnack(false)}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
  </>);
};

export default LotteryModal;







