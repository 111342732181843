import * as React from "react";
import { Container } from "@mui/material";
import Header from "./Header/header";
import Footer from "./Footer";

import useStyles from "./css";

export default function Layout(props: any) {
  const classes = useStyles();

  return (<>
    <Container component="main" className={classes.printWrapper} > 
    
        <Header />

        <Container style={{flexGrow: 1, padding: 0, 
              minHeight: "70vh", marginBottom: "50px"
        }} >
          {props.children}
        </Container>
      
        <Footer />
        
    </Container>
  </>);
}
