export default class Figure {
    id: string;
    name: string;
    // description: string;
    image: File | undefined;
    url: string;
    
    constructor(x?: any) {
        if (typeof x === "object" && x !== null && x !== undefined) {
            this.id = x.id;
            this.name = x.name;
            // this.description = x.description;
            this.image = x.image;
            this.url = x.url;
        }
        else {
            this.id = "";
            this.name = "";
            // this.description = "";
            // this.image = null;
            this.url = "";
        }
    }
}

