import React, { useEffect, useState  } from 'react';
import { Alert, Box, Button, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Apps, RecordVoiceOver } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import CloudLink from '../../api/cloud-link';
import LinkModel from '../../models/link-model';
import cloud from '../../api/cloud';
import Lottery from '../../models/lottery';
import GameCards from '../GameCards';
import GameTables from '../GameTables';
import { classic } from '../../api/classic';
import useStyles from "./css";


const Game = (props: {isClassic?: boolean}) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [lottery, setLottery] = useState<Lottery>();
  const [menu, setMenu] = useState("menu");
  const [openDrawer, setOpenDrawer] = useState(false);

  const params = useParams();
  
  useEffect(() => {

    setError("");

    if (params?.idLink! || props.isClassic! ) {

      if (props.isClassic || params?.idLink === "clasica" || params?.idLink === "classic") {
        loadClassic();
      }
      else {
        CloudLink.Get(params?.idLink!)
        .then(async (data) => {
        
          if (data) {
            const linkData = new LinkModel(data);

            if (linkData.isActive && linkData.idUser !== "" && linkData.idLottery !== "") 
              await loadCloud(linkData);
            else 
              handleError("Enlace vencido")

          } else {
            handleError("No es correcto el enlace")
          }

        })
        .catch((e: any) => {
          console.error(e);
          handleError("No es correcto el enlace")
        });
      }

    } else {
      handleError("Sin enlace")
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.idLink, props.isClassic]);
  
  
  const loadClassic = () => {
    classic.GetFigures("0").then((figs) => {
      classic.figures = figs;
      setLottery(classic);
      setLoading(false);
    });
  }

  const loadCloud = async (model: LinkModel) => {
    
    if (!model.idUser || !model.idLottery) {
      handleError("No es correcto el enlace")
      return;
    }

    try {
      const loteria = await cloud.Get4Game(model);
      cloud.Set(loteria);
      const figs = await cloud.GetFigures(model.idLottery, model.idUser);

      setLottery({...cloud, figures: figs});
      setLoading(false);
    }
    catch (e: any) {
      console.error(e);
      handleError("No es correcto el enlace")
    }

  }

  const handlePlay = (option: string) => {
    setMenu(option)
    setOpenDrawer(true)
  }

  const handleError = (msg: string) => {
    setLoading(false);
    setError(msg);
  }


  return (<>
  
    {
    loading 
    ? <>
        <div className={classes.body} >
            Cargando... 
        </div>
      </> 
    : <>
    
        {lottery ? <>
        
            <Box display="flex" justifyContent="center" marginTop={10} marginBottom={2}>
              <Typography>
                Selecciona el modo de juego:
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" marginTop={3} marginBottom={2}>
              <Button variant="contained" onClick={() => handlePlay("barajear")} style={{width: 200}} disabled={!lottery} >
                <RecordVoiceOver style={{marginRight: 10}} /> Barajear
              </Button>
            </Box>
            
            <Box display="flex" justifyContent="center" marginTop={3} marginBottom={2}>
              <Button variant="contained" onClick={() => handlePlay("jugar")} style={{width: 200}} disabled={!lottery} >
                <Apps style={{marginRight: 10}} /> Jugar
              </Button>
            </Box>

            <Drawer
              anchor={"bottom"}
              open={openDrawer}
              PaperProps={{
                style: {
                  width: '100vw',
                  height: '100vh',
                  zIndex: 1000, // Z-index para el Drawer
                },
              }}
        
            >

              <div 
                style={{
                  width: '95vw',
                  height: '100vh',
                  position: "relative",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: isMobile ? 10 : 50,
                  margin: "auto"
                }}>

              {menu === "barajear" ?
              <GameCards lottery={lottery} figures={lottery?.figures} onClose={() => setOpenDrawer(false)} />
              : null }

              {menu === "jugar" ?
              <GameTables lottery={lottery} figures={lottery?.figures} onClose={() => setOpenDrawer(false)} />
              : null }

              </div>

            </Drawer>

          </> 
          : <></>}


        {error && <>
          <div className={classes.body} >
            <Alert severity="warning" style={{width: "90%", margin: "auto"}}>
              {error}
            </Alert>
          </div>
          </>}
    

    </>}



  </>);
};

export default Game;

