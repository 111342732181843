import React, { useEffect, useState, useRef } from 'react';
import { Alert, Backdrop, Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { AddLink, ContentCopy, DeleteForever, OpenInNew, QrCode2 }  from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import QRCode from 'qrcode.react';

import Lottery from '../../models/lottery';
import CloudLink from '../../api/cloud-link';
import LinkModel from '../../models/link-model';
import { LotteryTypeEnum } from '../../models/lottery-type-enum';

import { GameLinkProps } from './props';
import useStyles from './css';


const GameLink = (props: GameLinkProps) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState<LinkModel | null>(null);
  const [lottery, setLottery] = useState<Lottery | null>(null);

  const [url, setUrl] = useState<string>("");
  const divRef = useRef<HTMLDivElement>(null);

  
  useEffect(() => {
    
    setLoading(true);
    
    if (props.lottery!) {

      setLottery(props.lottery);

      if (props.lottery.type === LotteryTypeEnum.Classic) {
        setClassicLink();
      } else {
        getCloud();
      }
      

    } else {
      setLoading(false); 
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lottery]);


  const setClassicLink = () => {
    const _url = `${window.location.origin}/clasica`;
    setUrl(_url);
    let _link = new LinkModel({
      id: 0,
      idUser: 0, 
      idLottery: 0,
      expire: 0,
      isActive: true
    });
    setLink(_link)
    setLoading(false); 
  }


  const getCloud = async () => {
    try {

      (async () => {

          const idLink = await CloudLink.GetIdLinkByLottery(props.lottery!.id);

          if (idLink) {
            
            const data = await CloudLink.Get(idLink)

            if (data)  {

              const linkData = new LinkModel(data);
              setLink(linkData);
              const _url = `${window.location.origin}/game/${linkData?.id}`;
              setUrl(_url);
              setLoading(false);

            } else 
            setLoading(false);

          } else 
          setLoading(false);

      })();

    }
    catch (e: any) {
      console.error(e);
    }
    finally {
      // setLoading(false);
    }
  }
  

  const handleCreateLink = () => {
    
    if (lottery) {
      setLoading(true);
      const idLink = uuidv4();

      CloudLink.AddUpdate(idLink, lottery.id)
      .then((linkData) => {
        if (linkData) {
          const _url = `${window.location.origin}/game/${linkData?.id}`;
          setUrl(_url);
          setLink(linkData);
        }

      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(()=> {
        setLoading(false);
      })

    }
  }


  const handleCopy = async () => {
    try {
      if (url)
        await navigator.clipboard.writeText(url);

    } catch (error) {
      console.error('Error al copiar el texto:', error);
    }
  };


  const isClipboardAvailable = () => navigator.clipboard && navigator.clipboard.write;


  const showErrorCopy = () => alert('Error al copiar QR al portapapeles. Inténtelo haciendo captura de pantalla.');

  
  const handleCopyQr = async () => {
    try {

      if (!url) return;
      if (!divRef.current) return;

      const canvas = divRef.current?.querySelector('canvas');
      
      if (!canvas) return;
      if (!isClipboardAvailable()) {
        alert('El navegador no permite copiar. Inténtelo haciendo captura de pantalla.');
        return;
      }

      canvas.toBlob((blob) => {
        if (blob) {
          const clipboardItem = new ClipboardItem({ 'image/png': blob });

          navigator.clipboard.write([clipboardItem]).then(
            () => {
              // console.log('Copiado al portapapeles');
            },
            (error) => {
              console.error('Error al copiar al portapapeles', error);
              showErrorCopy();
            }
          );
        }
      });

    } catch (error) {
      console.error('Error al copiar el QR:', error);
      showErrorCopy();
    }
  };


  const handleOpenUrl = () => {
    if (url)
      window.open(url, '_blank');
  }

  
  const handleDelete = async () => {

    if (link && link?.id !== "" && lottery && lottery?.id !== "") {

      Swal.fire({
        title: 'Confirmar',
        text: 'Se eliminará el enlace de la lotería.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#fa541c',
        cancelButtonColor: '#ccc',
        confirmButtonText: 'Sí, eliminar enlace',
        cancelButtonText: 'Cancelar',
      }).then(async (result: any) => {
          if (result.isConfirmed) {
              setLoading(true);
              
              CloudLink.Delete(link.id, lottery.id)
              .then((deleted) => {
                if (deleted) {
                  setLink(null);
                  setUrl("");
                }
              })
              .finally(() => setLoading(false));
      
          }
      });
    }
   
  };


  return (<>
    {
      link && link.id !== "" && url !== "" 
      ? <div className="no-print" style={{width: "100%", textAlign: "right", marginBottom: 20}}>
        
        <Tooltip title={"Copiar enlace"} arrow>
          <Button variant='text' onClick={handleCopy} >
            <ContentCopy style={{width: 20, height: 20}} /> 
          </Button>
        </Tooltip>

        {isClipboardAvailable() && <>
        <Tooltip title={"Copiar QR"} arrow>
          <Button variant='text' onClick={handleCopyQr} >
            <QrCode2 style={{width: 20, height: 20}} /> 
          </Button>
        </Tooltip>
        </>}

        <Tooltip title={"Abrir"} arrow>
          <Button variant='text' onClick={handleOpenUrl} >
            <OpenInNew style={{width: 20, height: 20}} />
          </Button>
        </Tooltip>
        
        {lottery?.type === LotteryTypeEnum.Cloud ? 
        
          <Tooltip title={"Eliminar"} arrow>
            <Button variant='text' onClick={handleDelete} >
              <DeleteForever style={{width: 20, height: 20}} /> 
            </Button>
          </Tooltip>

          : null }

        </div>
      : <></>
    }

    <Grid container spacing={3} className={classes.gridItem} style={{marginTop: 25, display: loading ? "none" : ""}}>

      <Grid item xs={12} sm={8} md={8} lg={8} className={classes.gridItem}>
          <Typography variant="h5">
            Enlace
          </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={8} lg={8} className={classes.gridItem}>
          {
            link && link.id !== "" && url !== "" 
            ? <Alert severity="info" style={{margin: "auto", position: "relative", fontWeight: "normal"}}>
                {url}
              </Alert>
            : <Button variant="contained" onClick={handleCreateLink} style={{}} disabled={!lottery} >
                <AddLink style={{marginRight: 10}} /> Generar
              </Button>
          }
      </Grid>

      
      <Grid item xs={12} sm={8} md={8} lg={8} className={classes.gridItem}>
        {
          url !== "" && <>
          <div key="divRef" ref={divRef} >
          <QRCode
            value={url!}
            size={256} // Tamaño del código QR
            level={'H'} // Nivel de corrección de errores (L, M, Q, H)
            bgColor={'#ffffff'} // Color de fondo
            fgColor={'#000000'} // Color del código
            includeMargin={true} // Incluir margen alrededor del código QR
            renderAs={'canvas'} // Formato de renderizado (canvas o svg)
          />
          </div>
          </>
        }
      </Grid>

    </Grid>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  </>);
};

export default GameLink;

