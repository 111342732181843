import { makeStyles } from '@mui/styles'; // https://stackoverflow.com/questions/72495622/npm-install-of-mui-styles-is-not-working-for-react-18

const useStyles = makeStyles((theme: any) => ({
  // root: {
  //   display: "flex",
  //   alignItems: "center",
  //   marginBottom: 24,
  //   width: "100%",
  //   justifyContent: "flex-end",
  // },
  head: {
    // background: "gray",
    flex: '1',
    padding: "0",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,

  },
  title: {
    fontSize: "3rem",
    fontFamily: "Inter, sans-serif",
    lineHeight: 1.2,
    color: "rgb(45, 55, 72)",
    fontWeight: 700,
    textAlign: "left",
    flexGrow: 1,
    textDecoration: 'none',
    margin: 0,
    padding: 0,
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  item: {
    marginLeft: 20,
    marginRight: 20,
  },
  lottery: {
    marginLeft: "auto",
  },
  auth: {
    marginLeft: "15px",
    // backgroundColor: "red",
  }

}));

export default useStyles;