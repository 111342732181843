export const Url = {
    Default: "/",
    Main: "/",
    Dashboard: "/dashboard",
    GameEdit: "/gameedit/:type/:id",
    GameEditClassic: "/gameedit/classic/0",
    GameEditLocal: "/gameedit/local/0",
    GameEditCloud: "/gameedit/cloud",
    GameClasica: "/clasica",
    GameClassic: "/classic",

    GameId: "/game/:idLink",
    GId: "/g/:idLink",
    
    Success: "/success",
    Faq: "/faq"
};