import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from 'react-ga';

import { RootState } from "./redux/store";
import { Url } from "./constants/url";
import Layout from "./components/Layout";
import Home from "./components/Home/home";
import Dashboard from "./components/Dashboard/";
import GameEdit from "./components/GameEdit";
import Game from "./components/Game/game";
import Faq from "./components/Faq";
import Success from "./components/Success";
import { CONSTANT } from "./constants";
import './App.css';


function App() {

  const { pathname } = useLocation();
  const location = useLocation();
  
  ReactGA.initialize(CONSTANT.analytics);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  const isAuthenticated = useSelector(
    (state: RootState) => true //state.authentication.value
  );

  return isAuthenticated ? (
    <Layout>
      <Routes>
        <Route path={Url.Main} element={<Home />} />
        <Route path={Url.Dashboard} element={<Dashboard />} />
        <Route path={Url.GameEdit} element={<GameEdit />} />

        <Route path={Url.GameClasica} element={<Game isClassic={true} />} />
        <Route path={Url.GameClassic} element={<Game isClassic={true} />} />
        
        <Route path={Url.GameId } element={<Game />} />
        <Route path={Url.GId } element={<Game />} />

        <Route path={Url.Success} element={<Success />} />
        <Route path={Url.Faq } element={<Faq />} />

        <Route path="*" element={<Navigate to={Url.Default}/>} />
      </Routes>
    </Layout>
  ) : (
    <></>
  );
}

export default App;
